import { DashboardActions } from '../actions';
import { DASHBOARD } from '../constants/actions';
import createReducer from './createReducer';

export const dashboard = createReducer(
	{},
	{
		[DASHBOARD.SET_ALL_DASHBOARD_VALUES](state = {}, action: ReturnType<typeof DashboardActions.setAllDashboardValues>) {
			return { ...state, dashboardValues: action.payload };
		},
		[DASHBOARD.GET_ALL_DASHBOARD_VALUES](state = {}, action: ReturnType<typeof DashboardActions.getAllDashboardValues>) {
			return { ...state, dashboardValues: action.payload };
		},

		[DASHBOARD.DISMISS_ABW_REMINDERS](state = {}, action: ReturnType<typeof DashboardActions.dismissAbwReminders>) {
			return { ...state, dismissAbwReminders: action.payload };
		},

		[DASHBOARD.DISMISS_CYCLE_COUNT_REMINDERS](state = {}, action: ReturnType<typeof DashboardActions.dismissCycleCountReminders>) {
			return { ...state, dismissCycleCountReminders: action.payload };
		},
	},
);