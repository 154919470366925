import moment from 'moment';
import { InventoryIcons, QtyRow } from './Renderers';
import { isObject } from 'lodash';
import { InventoryCustomBodyRenderFunction, UserDateFormat } from '.';

export const GetSerialInventoryColumns = (properties: any, suspendedTransactions?: any) => {
	const columnNames = [
		'customerName',
		'customerId',
		'cabinetId',
		'productLabelName',
		'cabinetType',
		'lot',
		'salesUoUQty',
		'expiration',
		'ndc542',
		'serialNo',
		'tagId',
		'ownership',
		'lotSize',
		'lotAssay',
		'lotUnitOfMeasure',
		'',
	];
	const columnLabels = [
		'Customer',
		'Customer Id',
		'Device',
		'Product Name',
		'Device Type',
		'Lot',
		'On Hand',
		'Expiry',
		'NDC',
		'Serial Number',
		'RFID Tag',
		'Owner',
		'Lot Size',
		'Lot Assay',
		'Lot Unit of Measure',
		'',
	];

	let columns = columnNames.map((name, index) => {
		const options: any = {
			filter: true,
			sort: true,
			filterList: null,
			display: true,
			sortOrder: null,
			viewColumns: true,
			change: true,
			customBodyRender: InventoryCustomBodyRenderFunction,
		};

		if (name === 'expiration') {
			options.sortCompare = (order: string) => {
				sortCompare(order);
			};
			options.customBodyRender = (value: any) => {
				return UserDateFormat(properties, value);
			};
		}

		if (name === 'salesUoUQty') {
			//if user loaded latest scan, we have an object of two values
			options.customBodyRender = (value: any) => {
				if (isObject(value)) {
					return QtyRow(value.dbValue, value.scanValue);
				} else {
					return QtyRow(value);
				}
			};
		}

		if (['lotSize', 'lotAssay', 'lotUnitOfMeasure'].includes(name)) {
			options.display = false;
		}

		if(name === 'tagId'){
			// Only show last 6 of tag
			options.customBodyRender = (value?: string) => {
				return value ? value.substring(value.length - 6) : undefined;
			}
		}

		if (name === '') {
			options.viewColumns = false
			options.customBodyRender = (value: any, tableMeta: any, updateValue: any) => {
				if(suspendedTransactions && suspendedTransactions.result){
					const suspendedLots = suspendedTransactions.result.map((transaction: any) => transaction.lot)
					const rowLot = tableMeta.rowData[5]
					return suspendedLots.includes(rowLot) ? InventoryIcons() : ""
				} 
				return ""
			};
		}

		return {
			name: name,
			label: columnLabels[index],
			options: options,
		};
	});

	return columns;
};

const sortCompare = (order: string) => {
	return (obj1: any, obj2: any) => {
		const val1 = obj1.data ? moment(obj1.data, 'YYYY-MM-DD').unix() : moment().unix();
		const val2 = obj2.data ? moment(obj2.data, 'YYYY-MM-DD').unix() : moment().unix();
		return (val1 - val2) * (order === 'asc' ? 1 : -1);
	};
};
