import { action } from 'typesafe-actions';
import { ORDERS } from '../constants/actions';

export const getOrderList = (payload: any) => action(ORDERS.LIST_REQUEST, payload);
export const setOrderList = (data: any) => action(ORDERS.LIST_RESPONSE, data);

export const getGroupedOrderList = (payload: any) => action(ORDERS.GROUPED_LIST_REQUEST, payload);
export const setGroupedOrderList = (data: any) => action(ORDERS.GROUPED_LIST_RESPONSE, data);

export const getShipments = (payload: any) => action(ORDERS.GET_SHIPMENTS, payload);
export const setShipments = (payload: any) => action(ORDERS.SET_SHIPMENTS, payload);
export const resetOrders = () => action(ORDERS.RESET_ORDERS);

export const updateRestockOrderStatus = (payload: any) => action(ORDERS.UPDATE_RESTOCK_ORDER_STATUS, payload);

export const getRecommendations = (payload: any) => action(ORDERS.GET_RECOMMENDATIONS, payload);
export const setRecommendations = (payload: any) => action(ORDERS.SET_RECOMMENDATIONS, payload);
export const setRecommendationsTotal = (payload: any) => action(ORDERS.SET_RECOMMENDATIONS_TOTAL, payload);
export const setInventoryExceptionsTotal = (payload: any) => action(ORDERS.SET_INVENTORY_EXCEPTIONS_TOTAL, payload);
export const setOrderExclusions = (payload: any) => action(ORDERS.SET_ORDER_EXCLUSIONS, payload);
export const syncOrderExclusions = (payload: any) => action(ORDERS.SYNC_ORDER_EXCLUSIONS, payload);
export const getParRecord = (payload: any) => action(ORDERS.GET_PAR_RECORD, payload);

export const releaseOrders = (payload: any) => action(ORDERS.RELEASE_ORDERS, payload);
export const getCustomerProductsForOrder = (payload: any) => action(ORDERS.GET_CUSTOMER_PRODUCTS_FOR_ORDER, payload);
export const setCustomerProductsForOrder = (payload: any) => action(ORDERS.SET_CUSTOMER_PRODUCTS_FOR_ORDER, payload);

export const updateOrder = (payload: any) => action(ORDERS.UPDATE_ORDER, payload);

export const checkOrderJobStatus = (payload: any) => action(ORDERS.CHECK_JOB_STATUS, payload)

export const syncWarehouseInventory = (payload: any) => action(ORDERS.SYNC_WAREHOUSE_INVENTORY, payload)

export const getOrderRequests = (payload: any) => action(ORDERS.GET_ORDER_REQUESTS, payload);
export const setOrderRequests = (data: any) => action(ORDERS.SET_ORDER_REQUESTS, data);

export const submitOrderRequest = (payload: any) => action(ORDERS.SUBMIT_ORDER_REQUEST, payload);
export const updateOrderRequests = (payload: any) => action(ORDERS.UPDATE_ORDER_REQUESTS, payload);
export const deleteOrderRequest = (payload: any) => action(ORDERS.DELETE_ORDER_REQUEST, payload);