import { connect } from 'react-redux';
import OrderManagementComponent from './order-mangement.component';
import { TenantsActions, UIAction, InventoryManagementActions, UserActions, OrderActions } from '../../redux/actions';
import { syncOrderExclusions } from '../../redux/sagas/orders.saga';

const mapStateToProps = (state: any) => ({
	inventory: state.orders.recommendations ? state.orders.recommendations.restockRecommendations : [],
	totalRecord: (state.orders.recommendations && state.orders.recommendations.totalRecords) ? state.orders.recommendations.totalRecords : 0,
	recommendationsTotal: (state.orders.recommendationsTotal) ? state.orders.recommendationsTotal : 0,
	inventoryExceptionsTotal: (state.orders.inventoryExceptionsTotal) ? state.orders.inventoryExceptionsTotal : 0,
	exclusions: state.orders.exclusions ? state.orders.exclusions : [],
	pendingOrderRequestTotal: state.orders.orderRequests ? state.orders.orderRequests.totalRecords.count : 0,
	authUser: state.user.authUser,
	selectedCustomer: state.ui.selectedCustomer,
	selectedFilterValues: state.ui.selectedFilterValues,
	globalRowsPerPage: state.ui.globalRowsPerPage ? state.ui.globalRowsPerPage : 10,
	properties: Array.isArray(state.user?.authUser?.record?.properties) ? state.user.authUser.record.properties : [],
	customers: state.tenants?.tenantsList?.result || [],
});

const mapDispatchToProps = (dispatch: any) => ({
	searchTenants: (filter: any) => dispatch(TenantsActions.getTenantList(filter)),
	getInventory: (filter: any) =>
		dispatch(OrderActions.getRecommendations(filter)),
	resetCustomers: () => dispatch(TenantsActions.clearTenantsList(true)),
	setSelectedCustomer: (customer: any) => dispatch(UIAction.setSelectedCustomer(customer)),
	setGlobalRowsPerPage: (rowsPerPage: number) => dispatch(UIAction.setGlobalRowsPerPage(rowsPerPage)),
	sendOneTimeReportRequest: (params: any) => dispatch(UserActions.sendOneTimeReport(params)),
	releaseOrders: (payload: any) => dispatch(OrderActions.releaseOrders(payload)),
	getCustomerProductsForOrder: (payload: any) => dispatch(OrderActions.getCustomerProductsForOrder(payload)),
	setDefaultStatusFilter: (value: string[]) => dispatch(UIAction.setMoreFilterValue({filterName: 'RESTOCK_RECOMMENDATION_STATUS', newValue: value})),
	syncOrderExclusions: (payload: any) => dispatch(OrderActions.syncOrderExclusions(payload)),
	getParRecord: (payload: any) => dispatch(OrderActions.getParRecord(payload)),
	submitOrderNotes: (payload: any) => dispatch(OrderActions.updateOrder(payload)),
	checkOrderJobStatus: (payload: any) => dispatch(OrderActions.checkOrderJobStatus(payload)),
	syncWarehouseInventory: (payload: any) => dispatch(OrderActions.syncWarehouseInventory(payload))
});

export const OrderManagementContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
)(OrderManagementComponent);
