import * as React from 'react';
import Loading from '../../components/loading';
import { Grid, Paper, Modal } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import DashboardTableChart from './dashboard-charts/dashboard-table.chart';
import modalSettingsJson from './dashboard-charts/chart.settings.json';
import DashboardPieChart from './dashboard-charts/dashboard-pie-chart.chart';
import DashboardBarGraphSideChart from './dashboard-charts/dashboard-bar-graph-side.chart';
import DashboardBarGraphChart from './dashboard-charts/dashboard-bar-graph.chart';
import DashboardStackedbarChart from './dashboard-charts/dashboard-stackedbar-graph.chart';
import { DashboardStyles, SideBarStyles, globalColors } from '../../hooks/styles';
import CancelIconSharp from '@material-ui/icons/CancelSharp';
import CheckCircleIconSharp from '@mui/icons-material/CheckCircleSharp';
import { ChartSettings, DashboardResults, FulfilledResult } from './dashboard.types';
import { DashboardCard } from './dashboard-card';
import GlobalFiltersComponentContainer from '../../components/globalFiltersComponent';
import { valueMapper } from '../../hooks/functions';
import { Link } from 'react-router-dom';
import { DashboardReminders } from './dashboard-reminders';

const DashboardComponent: React.FC<any> = (props: any) => {
    const navStyles = SideBarStyles();
    const [chartType, setChartType] = React.useState<string | null | undefined>(null);
    const [modalRecords, setChartRecords] = React.useState<{ [key: string]: any }[] | null>(null);
    const [modalSettings, setChartSettings] = React.useState<{ [key: string]: any } | null>(null);
    const dashboardStyling = DashboardStyles();
    const [dashboardRole, setDashboardRole] = React.useState<string | undefined>(undefined);
    const [dashboardType, setDashboardType] = React.useState<'OPERATIONS' | 'SERVICES' | 'CUSTOMER' | undefined>();
    const [open, setChartOpen] = React.useState(false);

    React.useEffect(() => {
        let dType: 'OPERATIONS' | 'SERVICES' | 'CUSTOMER' | undefined;
        if(dashboardRole === 'dashboard_operations') dType = 'OPERATIONS';
        if(dashboardRole === 'dashboard_services') dType = 'SERVICES';
        if(dashboardRole === 'dashboard_customer') dType = 'CUSTOMER';

        if(dType) setDashboardType(dType);
    }, [dashboardRole])

    React.useEffect(() => {
        const currentUser = props.authUser.record;
        const roles = currentUser && currentUser.claims ? currentUser.claims[Object.keys(currentUser.claims)[0]] : null;

        const dashboardRoles = ['dashboard_operations', 'dashboard_services', 'dashboard_customer']
        const role = dashboardRoles.find(el => Object.keys(roles).includes(el));

        if (role) setDashboardRole(role);
    }, [props.authUser.record]);

    const handleOpen = () => {
        if (!open) setChartOpen(true);
    };

    const handleChartClose = () => {
        setChartOpen(false);
    };

    const showLoadingMsg = () => !props.authUser || !dashboardValues.fulfilled;

    const Card = styled(Paper)(({ theme }) => ({
        padding: theme.spacing(1),
        textAlign: 'center',
        alignItems: 'center',
        alignContent: 'center'
    }));

    // Settings maintained here: src/pages/dashboard/dashboard-modals/modal.settings.json
    const itemSettings = modalSettingsJson as ChartSettings[];
    const dashboardValues = props.dashboardValues as DashboardResults;

    // Define custom display of card and/or the status to color the card if desired
    function getCardStatusTotals(record: FulfilledResult) {

        // Default totals to return if no custom formatting needed
        const totals: { display: string | number, status: 'Pass' | 'Warning' | 'Fail' } = {
            display: Intl.NumberFormat("en-US")
                .format(record.summaryTotals?.currentDayRecords ?? record.summaryTotals?.totalRecords ?? 0),
            status: 'Pass'
        }

        if (record.summaryName === 'jobStatuses') {
            const jobTotals = record.summaryTotals?.jobTotals;
            const totalJobs = record.summaryTotals?.totalRecords;

            if (jobTotals && totalJobs) {

                if (jobTotals.inProgressJobs) totals.status = 'Warning';
                if (jobTotals.failedJobs > 0) totals.status = 'Fail';

                const incompleteJobs = jobTotals.failedJobs + jobTotals.inProgressJobs;
                totals.display = `${totalJobs - incompleteJobs}/${totalJobs}`
            }
        }

        return totals;
    }

    function getTitle(roleName?: string) {
        const roleTitles = [
            { role: 'dashboard_operations', title: 'RNI Operations' },
            { role: 'dashboard_services', title: 'RNI Services' },
            { role: 'dashboard_customer', title: 'Customer' }
        ];

        return roleTitles.find(el => el.role === roleName)?.title ?? '';
    }

    const getDashboardCard = (itemSetting: ChartSettings, record: any) => {
        return <DashboardCard
            itemSetting={itemSetting}
            record={record}
            getCardStatusTotals={getCardStatusTotals}
            handleOpen={handleOpen}
            setChartRecords={setChartRecords}
            setChartType={setChartType}
            setChartSettings={setChartSettings}
            dashboardStyling={dashboardStyling}
        />
    }

    const getSearchParams = () => {
        let params = []
        if (props.selectedCustomer) {
            params.push({key: 'customerId', value: props.selectedCustomer.customerId})
        }
        return params
    }

    const onSearchClick = () => {
        props.getDashboardValues({
            dashboardType: dashboardType,
            params: getSearchParams()
        })

        const cabinetFilter: any = {
			customerId: props.selectedCustomer ? props.selectedCustomer.customerId : '',
        }
        props.getCabinets(cabinetFilter);
    }

    return (
        <div>
            <h2 style={{marginTop: 0}}>
                {getTitle(dashboardRole)}
            </h2>
            
            <div>
                {dashboardType === 'CUSTOMER' && <Grid container>
                    <Grid xs={12}>
                        <GlobalFiltersComponentContainer
                            pageName={'DASHBOARD'}
                            executeSearch={onSearchClick}
                        />
                    </Grid>
                </Grid>}
                {/* Top Level Summaries */}
                {showLoadingMsg() ? (
                    <div>
                        {/* <Loading message="" /> */}
                    </div>
                ) :
                    <>
                        <Grid container spacing={2}>
                            {dashboardValues.fulfilled.results && dashboardValues.fulfilled.results.map((el) => {

                                if (dashboardType === 'CUSTOMER' && el.summaryName === 'dashboardReminders') {
                                    return <DashboardReminders
                                        authUser={props.authUser} 
                                        customerId={props.selectedCustomer?.customerId} 
                                        reminders={el.records}
                                        dismissals={{
                                            abw: props.abwRemindersDismissed,
                                            dismissAbw: props.dismissAbwReminders,
                                            cycleCount: props.cycleCountRemindersDismissed,
                                            dismissCycleCount: props.dismissCycleCountReminders
                                        }} 
                                    />
                                }

                                const itemSetting = itemSettings.find(setting =>
                                    setting.summaryName === el.summaryName
                                    && setting.placement === 'top'
                                );

                                if (itemSetting) {
                                    return (
                                        <Grid item key={el.summaryName} xs={1}>
                                            <Card elevation={4}>
                                                <div className={dashboardStyling.cardItemWithIcon}>
                                                    {el.connection === 'Success' ?
                                                        <CheckCircleIconSharp style={{ color: 'green' }} fontSize='small'></CheckCircleIconSharp> :
                                                        <CancelIconSharp style={{ color: 'red' }} fontSize='small'></CancelIconSharp>
                                                    }
                                                    {itemSetting.displayName}
                                                </div>
                                            </Card>
                                        </Grid>
                                    )
                                }

                            })
                            }

                        </Grid>
                        <br></br>
                        {/* Center level summaries */}
                        <Grid container spacing={2}>
                            {dashboardValues.fulfilled.results && dashboardValues.fulfilled.results.map((el) => {

                                const itemSetting = itemSettings.find(setting =>
                                    setting.summaryName === el.summaryName
                                    && (!setting.placement || setting.placement === 'center')
                                );

                                if (itemSetting) {
                                    return dashboardType === 'CUSTOMER' ? 
                                        <>
                                            {el.summaryTotals && el.summaryTotals.totalRecords > 0 && <Grid item key={el.summaryName} xs={12} sm={6} md={4}>
                                                {getDashboardCard(itemSetting, el)}
                                            </Grid>}
                                        </>
                                    :
                                        <Grid item key={el.summaryName} xs={12} sm={6} md={4} lg={3} xl={2}>
                                            {getDashboardCard(itemSetting, el)}
                                        </Grid>
                                }

                            })}
                        </Grid>
                        <br></br>
                        {/* Bottom Level Summaries */}
                        <Grid container spacing={3}>
                            {dashboardValues.fulfilled.results && dashboardValues.fulfilled.results.map((el) => {

                                const itemSetting = itemSettings.find(setting =>
                                    setting.summaryName === el.summaryName
                                    && setting.placement === 'bottom'
                                );

                                if (itemSetting) {
                                    return (
                                        <Grid item key={el.summaryName} xs={12} sm={dashboardType === 'CUSTOMER' ? 6 : 3}>
                                            <Card elevation={4}>
                                                <div className={dashboardStyling.cardItem}>
                                                    {itemSetting.chartType === 'pieChart' && < DashboardPieChart records={el.records} settings={itemSetting} animation={false} />}
                                                    {itemSetting.chartType === 'table' && < DashboardTableChart records={el.records} settings={itemSetting} animation={false} />}
                                                    {itemSetting.chartType === 'stackedBar' && < DashboardStackedbarChart records={el.records} settings={itemSetting} animation={false} />}
                                                </div>
                                            </Card>
                                        </Grid>
                                    )
                                }

                            })
                            }

                        </Grid>
                        {dashboardType === 'CUSTOMER' && <div style={{ margin: '20px 0' }}>
                            <h2>RNI Devices</h2>
                            <Grid container spacing={3}>
                                {props.cabinets.map((cabinet: any) => (
                                    <Grid item xs={3}>
                                        <Link className={navStyles.link} to={`/cabinet-details/${cabinet.customerId}/${cabinet.cabinetId}`}>
                                            <Paper className={dashboardStyling.paper}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs>
                                                        {/* Placeholder for cabinet icon when available */}
                                                        {/* <div style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                                                            <CloudIcon />
                                                        </div> */}
                                                        <b>{valueMapper(cabinet.cabinetType, 'cabinetTypes')}</b>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        Device Name: {cabinet.cabinetName || 'Unknown'}
                                                        <br />
                                                        Device Id: {cabinet.cabinetId}
                                                        <br />
                                                        Status: {cabinet.cabinetProperties?.connectivityStatus ? valueMapper(cabinet.cabinetProperties.connectivityStatus, 'connectivityStatus') : 'UNKNOWN'}
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Link>
                                    </Grid>
                                ))}
                            </Grid>
				        </div>}
                    </>
                }
            </div>
            <Modal
                open={open}
                onClose={() => handleChartClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div>
                    {chartType === 'table' && < DashboardTableChart records={modalRecords} settings={modalSettings} properties={props.properties} closeChart={handleChartClose} />}
                    {chartType === 'pieChart' && < DashboardPieChart records={modalRecords} settings={modalSettings} closeChart={handleChartClose} />}
                    {chartType === 'barGraphSide' && < DashboardBarGraphSideChart records={modalRecords} settings={modalSettings} closeChart={handleChartClose} />}
                    {chartType === 'barGraph' && < DashboardBarGraphChart records={modalRecords} settings={modalSettings} closeChart={handleChartClose} />}
                </div>
            </Modal>
        </div>
    );
}

export default DashboardComponent;
