import * as React from 'react';
import { Button, FormControl, TextField } from '@material-ui/core';
import { CommonStyles, getModalStyle, getModalClasses } from '../../hooks/styles';
import { useMediaQuery } from 'react-responsive';
import CancelIcon from '@material-ui/icons/Cancel';
import { OrderActions } from '../../redux/actions';
import { connect } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const ProcessOrderRequestsModal: React.FC<any> = (props: any) => {
	const commonStyles = CommonStyles();
	const modalClasses = getModalClasses();
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});
  const [orderRequests, setOrderRequests] = React.useState<any[]>(props.orderRequests)
  const [adminNotes, setAdminNotes] = React.useState<string>('')
  const [status, setStatus] = React.useState<string>('')
  const [adminNotesError, setAdminNotesError] = React.useState<string>('')
  const [orderNotes, setOrderNotes] = React.useState<string>('')

  const handleSubmit = () => {
    if (status === 'DENIED' && !adminNotes) {
      setAdminNotesError('A note is required to explain why these order requests are being denied.')
      return
    }

    const requestsToProcess = orderRequests.map((or: any) =>  {
      const orderRequest = {...or, 
        status: status, 
        adminNotes: adminNotes || undefined,
        orderNotes: orderNotes || undefined,
        updatedBy: props.authUser.record.email,
        approvedQuantity: getApprovedQuantity(or),
        product: undefined,
        customer: undefined,
        warehouseInventory: undefined,
        par: undefined,
        defaultBatch: undefined
      }
      if ((!orderRequest.batches || orderRequest.batches.length === 0) && Object.keys(or.defaultBatch).length > 0) {
        orderRequest.batches = [{
          quantity: orderRequest.approvedQuantity,
          lot: or.defaultBatch.lotNumber,
          expiration: or.defaultBatch.expirationDate,
          warehouse: or.defaultBatch.warehouse
        }]
      }
      return orderRequest
    })

    props.updateOrderRequests({
      body: requestsToProcess,
      filter: [{key: 'filter', value: JSON.stringify(props.orderRequestFilter)}],
      complete: () => {
        props.clearSelections();
        props.close(false);
      }
    })
  }

  const getApprovedQuantity = (orderRequest: any) => {
    if (status === 'DENIED') {
      return null
    }
    if (orderRequest.approvedQuantity) {
      return orderRequest.approvedQuantity
    }
    if (!orderRequest.approvedQuantity && status === 'APPROVED') {
      return orderRequest.requestedQuantity
    }
  }

	return (
		<div
			style={{
				...getModalStyle(),
				width: isDesktopOrLaptop ? 'auto' : '90%',
				maxHeight: 'auto',
				maxWidth: 750,
				overflow: 'scroll',
			}}
			className={modalClasses.paper}
		>
			<div
				style={{ position: 'absolute', top: 5, right: 5, color: 'rgba(0, 0, 0, 0.26)', cursor: 'pointer' }}
				onClick={() => props.close(false)}
			>
				<CancelIcon />
			</div>
			<h2>Process Order Requests</h2>
      <p>You've selected {props.orderRequests.length} order requests to be processed.</p>
      <div>
        <FormControl>
          <Button
            type="button"
            className={commonStyles.searchButton}
            variant="contained"
            color="primary"
            onClick={() => setStatus('APPROVED')}
          >
            {status === 'APPROVED' && <CheckCircleIcon fontSize='small' style={{marginRight: 5}} />}
            Approve All
          </Button>
        </FormControl>
        <FormControl>
          <Button
            type="button"
						className={commonStyles.cancelBtn}
            style={{margin: '12px 0 0 10px'}}
						variant="contained"
						color="default"
            onClick={() => setStatus('DENIED')}
          >
            {status === 'DENIED' && <CheckCircleIcon fontSize='small' style={{marginRight: 5}} />}
            Deny All
          </Button>
        </FormControl>
      </div>
      {status === 'DENIED' && <div style={{marginTop: 10}}>
        <FormControl variant="outlined" style={{ marginRight: 10 }}>
          <TextField
            variant="outlined"
            label="Please provide an explanation for why these order requests are being denied."
            style={{ width: 530 }}
            value={adminNotes || ''}
            onChange={event => {
              if (event.target.value) {
                setAdminNotesError('')
              }
              setAdminNotes(event.target.value)
            }}
            error={adminNotesError ? true : false}
            helperText={adminNotesError}
            required
          />
        </FormControl>
      </div>}
      {status === 'APPROVED' && <div style={{marginTop: 10}}>
        <FormControl variant="outlined" style={{ marginRight: 10 }}>
          <TextField
            variant="outlined"
            label="Admin Notes (optional)"
            style={{ width: 530 }}
            value={adminNotes || ''}
            onChange={event => {
              if (event.target.value) {
                setAdminNotesError('')
              }
              setAdminNotes(event.target.value)
            }}
          />
        </FormControl>
      </div>}
      {status === 'APPROVED' && <div style={{marginTop: 10}}>
        <FormControl variant="outlined" style={{ marginRight: 10 }}>
          <TextField
            variant="outlined"
            label="Order Notes (optional)"
            style={{ width: 530 }}
            value={orderNotes || ''}
            onChange={event => {
              setOrderNotes(event.target.value)
            }}
          />
        </FormControl>
      </div>}
      {status && <div>
        <FormControl>
          <Button
            type="button"
            className={commonStyles.searchButton}
            variant="contained"
            color="primary"
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </FormControl>
      </div>}
		</div>
	);
};

const mapStateToProps = (state: any) => ({
  authUser: state.user.authUser
});

const mapDispatchToProps = (dispatch: any) => ({
	updateOrderRequests: (payload: any) => dispatch(OrderActions.updateOrderRequests(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProcessOrderRequestsModal);
