import { action } from 'typesafe-actions';
import { INVENTORY_MANAGEMENT } from '../constants/actions';
import { ParInventoryRow, TenantInventoryRow } from '../models/inventory-management/inventory-management';

export const parInventoryRequestStartAction = (filter: any | null) => action(INVENTORY_MANAGEMENT.LIST_PAR, filter);
export const parInventoryRequestCompleteAction = (parInventory: ParInventoryRow[]) =>
	action(INVENTORY_MANAGEMENT.LIST_PAR_RESPONSE, parInventory);
export const parInventoryGroupRequestCompleteAction = (parInventoryGroup: any) =>
	action(INVENTORY_MANAGEMENT.LIST_PAR_GROUP_RESPONSE, parInventoryGroup);

export const setInventoryFilterAction = (inventoryFilter: any) =>
	action(INVENTORY_MANAGEMENT.SET_INVENTORY_FILTER, inventoryFilter);

export const tenantInventoryRequestStartAction = (tenantId: string) =>
	action(INVENTORY_MANAGEMENT.LIST_INVENTORY, tenantId);
export const tenantInventoryRequestCompleteAction = (tenantInventory: TenantInventoryRow[]) =>
	action(INVENTORY_MANAGEMENT.LIST_INVENTORY_RESPONSE, tenantInventory);
export const groupedInventoryRequestCompleteAction = (tenantInventory: TenantInventoryRow[]) =>
	action(INVENTORY_MANAGEMENT.GROUPED_INVENTORY_RESPONSE, tenantInventory);

export const cartridgeInventoryRequestStartAction = (tenantId: string) =>
	action(INVENTORY_MANAGEMENT.CARTRIDGE_INVENTORY, tenantId);
export const cartridgeInventoryRequestCompleteAction = (tenantInventory: TenantInventoryRow[]) =>
	action(INVENTORY_MANAGEMENT.CARTRIDGE_INVENTORY_RESPONSE, tenantInventory);
export const groupedCartridgeInventoryRequestCompleteAction = (tenantInventory: TenantInventoryRow[]) =>
	action(INVENTORY_MANAGEMENT.GROUPED_CARTRIDGE_INVENTORY_RESPONSE, tenantInventory);

export const serialInventoryRequestStartAction = (tenantId: string) =>
	action(INVENTORY_MANAGEMENT.SERIAL_INVENTORY, tenantId);
export const serialInventoryRequestCompleteAction = (tenantInventory: TenantInventoryRow[]) =>
	action(INVENTORY_MANAGEMENT.SERIAL_INVENTORY_RESPONSE, tenantInventory);
export const groupedSerialInventoryRequestCompleteAction = (tenantInventory: TenantInventoryRow[]) =>
	action(INVENTORY_MANAGEMENT.GROUPED_SERIAL_INVENTORY_RESPONSE, tenantInventory);

export const setCompleteCustomerInventory = (customerId: string) =>
	action(INVENTORY_MANAGEMENT.SET_COMPLETE_INVENTORY, customerId);

export const cabinetInventoryQtyRequestStartAction = (payload: any, tenantId: string) =>
	action(INVENTORY_MANAGEMENT.SET_QUANTITY, payload, tenantId);
export const cabinetInventoryReceiptRequestStartAction = (payload: any, tenantId: string) =>
	action(INVENTORY_MANAGEMENT.UPDATE_RECEIPT, payload, tenantId);

export const getSuspendedTransactions = (payload: any) =>
	action(INVENTORY_MANAGEMENT.GET_SUSPENDED_TRANSACTIONS, payload);
export const setSuspendedTransactions = (payload: any) =>
	action(INVENTORY_MANAGEMENT.SET_SUSPENDED_TRANSACTIONS, payload);
export const updateSuspendedTransactions = (payload: any) =>
	action(INVENTORY_MANAGEMENT.UPDATE_SUSPENDED_TRANSACTIONS, payload);

export const inventoryManagementError = (error: boolean) => action(INVENTORY_MANAGEMENT.ERROR, error);

export const tenantInventoryClearAction = () => action(INVENTORY_MANAGEMENT.CLEAR_TENANT_INVENTORY);

export const inventoryLogExport = (filter: any) => action(INVENTORY_MANAGEMENT.INVENTORY_CSV, filter);

export const getTags = (payload: any) => action(INVENTORY_MANAGEMENT.GET_TAGS, payload);
export const setTags = (payload: any) => action(INVENTORY_MANAGEMENT.SET_TAGS, payload);
export const getTagLifecycle = (payload: any) => action(INVENTORY_MANAGEMENT.GET_TAG_LIFECYCLE, payload);
export const setTagLifecycle = (payload: any) => action(INVENTORY_MANAGEMENT.SET_TAG_LIFECYCLE, payload);
export const updateProductTagSerials = (payload: any) => action(INVENTORY_MANAGEMENT.UPDATE_PRODUCT_TAG_SERIALS, payload);
export const forceVend = (payload: string) => action(INVENTORY_MANAGEMENT.FORCE_VEND, payload);
export const revertTag = (payload: any) => action(INVENTORY_MANAGEMENT.REVERT_PRODUCT_TAG_SERIALS, payload);
export const returnProductTagSerialsRecord = (payload: any) => action(INVENTORY_MANAGEMENT.RETURN_PRODUCT_TAG_SERIALS, payload);
export const setProductOwnerForTag = (tagId: string, owner: string) =>
	action(INVENTORY_MANAGEMENT.SET_PRODUCT_OWNERSHIP, tagId, owner);

export const getRestockRecommendations = (payload: any) => action(INVENTORY_MANAGEMENT.GET_RESTOCK_RECOMMENDATIONS, payload);
export const setRestockRecommendations = (payload: any) => action(INVENTORY_MANAGEMENT.SET_RESTOCK_RECOMMENDATIONS, payload);

export const bulkUpdateProductTagSerials = (payload: any) => action(INVENTORY_MANAGEMENT.BULK_UPDATE_PRODUCT_TAG_SERIALS, payload);
export const bulkForceVend = (payload: string) => action(INVENTORY_MANAGEMENT.BULK_FORCE_VEND, payload);
export const bulkRevertTag = (payload: any) => action(INVENTORY_MANAGEMENT.BULK_REVERT_PRODUCT_TAG_SERIALS, payload);
export const bulkReturnProductTagSerialsRecord = (payload: any) => action(INVENTORY_MANAGEMENT.BULK_RETURN_PRODUCT_TAG_SERIALS, payload);
export const bulkSetProductOwnerForTag = (payload: any) =>
	action(INVENTORY_MANAGEMENT.BULK_SET_PRODUCT_OWNERSHIP, payload);

export const refreshPar = (customerId: any) => action(INVENTORY_MANAGEMENT.REFRESH_PAR, customerId);
