import { DashboardActions } from "../actions";
import { DashboardService } from "../services";
import { call, put } from 'redux-saga/effects';

export function* getDashboardValues(action: ReturnType<typeof DashboardActions.getAllDashboardValues>) {
	try {
		const { data } = yield call(DashboardService.getInstance().getDashboardValues, action.payload.dashboardType, action.payload.params);

		yield put(DashboardActions.setAllDashboardValues(data));
		// This would bring the reminders back if they are still present after refreshing the dashboard data
		// yield put(DashboardActions.dismissAbwReminders(false));
		// yield put(DashboardActions.dismissCycleCountReminders(false));
	} catch (e) {
		console.log('dashboard values saga error', e);
	}
}