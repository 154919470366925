import React from 'react';
import { connect } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import { getMuiTheme } from '../../hooks/styles';
import { CommonStyles } from '../../hooks/styles';
import { InvoicesActions, UIAction, UserActions } from '../../redux/actions';
import dataList from '../../constants/dataList';
import EmptyContent from '../../components/emptyContent';
import GlobalFiltersComponentContainer from '../../components/globalFiltersComponent';
import moment from 'moment';
import { DateTime, DurationObjectUnits } from 'luxon';
import FormControlLabel from '@mui/material/FormControlLabel';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import {
	PatchInvoiceAbwDto,
	PatchInvoiceAbwPayload,
	PatchInvoiceOmitDto,
	PatchInvoiceReadyPayload,
	PatchInvoiceReadyStatusFilterDto,
	PatchInvoiceStatus,
	SendAbwReminderEmail,
	TransmitToSapRequest,
} from '../../redux/models/invoices/invoices';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { useLocation, useParams } from 'react-router-dom';
import { Box, FormControl, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import ConfirmDialog from '../../components/dialog/confirm';
import Switch from '@mui/material/Switch';
import PauseCircle from '@mui/icons-material/PauseCircle';
import { constructEnqueueParams, findUserSelectedColumns } from '../../helpers/report-queue.helper';
import { GetUserTimezone } from '../../hooks/functions';

type MUIDataTableMeta = {
	rowIndex: number;
	dataIndex: number;
};

interface InvoiceRequestsProps {
	invoicesList?: any;
	openPaymentPage?: any;
	getInvoiceList?: any;
	properties: any;
	globalRowsPerPage: any;
	setGlobalRowsPerPage: (rowsPerPage: number) => void;
	isCCPaymentMode: boolean;
}

interface InvoiceRequest {
	id: string;
	customerId: string;
	cabinetId: string;
	productId: string;
	lot: string;
	serialNo: string;
	rfidTag: string;
	dispenseDate: string;
	shipFromWarehouse?: string;
	abwCode?: string;
	salesDocType: string;
	quantity: number;
	reason: string;
	status: string;
	sapUniqueId: number;
	usageId: number;
	omitted: boolean;
	omittedReason?: string;
	omittedNote?: string;
	transmitted: string;
	sapXmlFileName?: string;
	createdAt: string;
	updatedAt: string;
	customerName: string;
	customerManualSplitBill: string;
	customerAutoSplitBill?: string;
	cabinetType: string;
	source: string;
	productName: string;
	ndc: string;
}

const InvoiceRequests: React.FC<any> = (props: any) => {
	const muiThemeStyle = getMuiTheme();
	const classes = CommonStyles();
	const firstRender = React.useRef(true);

	const urlParams = useParams<any>();
	const [customerId, setCustomerId] = React.useState<string>(props.selectedCustomer?.customerId || urlParams.customerId)

	const [invoiceRequests, setInvoiceRequests] = React.useState<any[]>([]);
	const [tableStatePersist, setTableStatePersist] = React.useState(null as any);
	const [pagination, setPageState] = React.useState({
		offset: 0,
		limit: props.globalRowsPerPage,
		page: 0,
	});
	const [loadingOmitted, setLoadingOmitted] = React.useState(false);
	const [openOmitDialog, setOpenOmitDialog] = React.useState(false);
	const [omitConfirmationObj, setOmitConfirmationObj] = React.useState({
		error: false,
		errorHelpText: '',
		reason: '',
		note: '',
	});
	const [rga, setRga] = React.useState('');
	const [rgaError, setRgaError] = React.useState(false)
	const isAdminPage = useLocation().pathname.startsWith('/admin-invoices');
	const isDev =
		props.authUser.email === 'dgwilt@fffenterprises.com' || props.authUser.email === 'dweaver@fffenterprises.com';
	const [confirmSubmitAllInvoicesOpen, setConfirmSubmitAllInvoicesOpen] = React.useState(false);
	const [confirmSendReminderEmailOpen, setConfirmSendReminderEmailOpen] = React.useState(false);
	const [confirmMoveInvoicesToReadyOpen, setConfirmMoveInvoicesToReadyOpen] = React.useState(false);
	const [confirmSaveAbwSelectionsOpen, setConfirmSaveAbwSelectionsOpen] = React.useState(false);
	const [omitDialogInvoiceIndex, setOmitDialogInvoiceIndex] = React.useState(0);
	const [abwSelections, setAbwSelections] = React.useState<PatchInvoiceAbwDto[]>([]);
	const [orderInvoiceRequestList, setOrderInvoiceRequestList] = React.useState('');
	const [invoicesSelectedToOmit, setInvoicesSelectedToOmit] = React.useState<number[]>([]);

	const isCCPayments = Boolean(props.isCCPaymentMode);

	const { autoload } = props;
	React.useEffect(() => {
		if (autoload) {
			// Load data on page load
			onSearchClick();
		}
	}, [autoload]);

	React.useEffect(() => {
		if (props.invoiceRequests) {
			setInvoiceRequests(props.invoiceRequests);
		}
	}, [props.invoiceRequests]);
	React.useEffect(() => {
		if (props.authUser && !firstRender.current) {
			handleGetInvoiceRequests();
			props.disableSerialNumberFilter(false);
		} else {
			firstRender.current = false;
		}
	}, [pagination]);

	const onSearchClick = () => {
		setPageState({
			offset: 0,
			limit: isAdminPage ? props.globalRowsPerPage : 10000,
			page: 0,
		});
	};

	React.useEffect(() => {
		if (!firstRender.current && !props.selectedCustomer) {
			setCustomerId('')
			return
		}
		setCustomerId(props.selectedCustomer.customerId)
	}, [props.selectedCustomer])


	const getFilter = () => {
		const customerIdFilter = customerId
		let queryParams: any = {
			offset: pagination.offset,
			limit: pagination.limit,
			skip: 0,
			customerId: customerIdFilter,
			order: orderInvoiceRequestList !== '' ? orderInvoiceRequestList : undefined,
			productIds:
				props.selectedFilterValues && props.selectedFilterValues['PRODUCT_NAME_MULTIPLE']
					? props.selectedFilterValues['PRODUCT_NAME_MULTIPLE'].map((product: any) => product.productItemId)
					: undefined,
			productGroups:
				props.selectedFilterValues && props.selectedFilterValues['PRODUCT_GROUP_MULTIPLE']
					? props.selectedFilterValues['PRODUCT_GROUP_MULTIPLE']
					: undefined,
			status: !isAdminPage
				? 'PENDING ABW'
				: props.selectedFilterValues && props.selectedFilterValues['INVOICE_STATUS']
				? props.selectedFilterValues['INVOICE_STATUS']
				: undefined,
			startDate: isAdminPage && props.selectedDateRange ? props.selectedDateRange.startDate : undefined,
			endDate: isAdminPage && props.selectedDateRange ? props.selectedDateRange.endDate : undefined,
			transmittedStartDate:
				isAdminPage && props.selectedTransmittalDate
					? moment(props.selectedTransmittalDate).startOf('day').format('YYYY-MM-DD HH:mm:ss')
					: undefined,
			transmittedEndDate:
				isAdminPage && props.selectedTransmittalDate
					? moment(props.selectedTransmittalDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
					: undefined,
			abwCode:
				isAdminPage && props.selectedFilterValues && props.selectedFilterValues['INVOICE_ABW']
					? props.selectedFilterValues['INVOICE_ABW']
					: undefined,
			cabinetType:
				isAdminPage && props.selectedFilterValues && props.selectedFilterValues['CABINET_TYPE']
					? props.selectedFilterValues['CABINET_TYPE']
					: undefined,
			lot:
				isAdminPage && props.selectedFilterValues && props.selectedFilterValues['LOT_NUMBER']
					? props.selectedFilterValues['LOT_NUMBER']
					: undefined,
			serialNo:
				isAdminPage && props.selectedFilterValues && props.selectedFilterValues['SERIAL_NUMBER']
					? props.selectedFilterValues['SERIAL_NUMBER']
					: undefined,
		};

		if (props.isCCPaymentMode) {
			queryParams['getCCPayableOnly'] = true;
		}

		return queryParams;
	};

	const handleGetInvoiceRequests = () => {
		props.getInvoiceRequests(getFilter());
	};

	const handleOmitDialogClose = () => {
		setRga('')
		setOmitConfirmationObj({
			error: false,
			errorHelpText: '',
			reason: '',
			note: '',
		})
		setOpenOmitDialog(false);
	};

	const handleOmitDialogOpen = (newInvoiceIndex: number) => {
		setOmitDialogInvoiceIndex(newInvoiceIndex);
		setOpenOmitDialog(true);
	};

	const handleBulkOmitDialogOpen = () => {
		if (invoicesSelectedToOmit.length < 1) return
		setOpenOmitDialog(true);
	};

	

	const handleChangeAbw = (dataIndex: number, event: React.ChangeEvent<HTMLInputElement>) => {
		const newInvoiceRequest = { ...invoiceRequests[dataIndex] };
		newInvoiceRequest.abwCode = (event.target as HTMLInputElement).value;
		console.log('new Invoice Request: ', newInvoiceRequest);

		const newInvoiceRequestList = [...invoiceRequests];
		newInvoiceRequestList[dataIndex] = newInvoiceRequest;
		const newAbwSelections = [...abwSelections];
		const abwSelectedIndex = newAbwSelections.findIndex(item => item.id === newInvoiceRequest.id);
		if (abwSelectedIndex === -1) {
			newAbwSelections.push({
				id: newInvoiceRequest.id,
				abwCode: newInvoiceRequest.abwCode,
			});
		} else {
			newAbwSelections[abwSelectedIndex].abwCode = newInvoiceRequest.abwCode;
		}

		setAbwSelections(newAbwSelections);
		setInvoiceRequests(newInvoiceRequestList);
	};

	const handleSendAllToSap = () => {
		// Call Send All Invoices to SAP API
		let payload: { [key: string]: any } = {};
		if (props?.selectedCustomer) payload.customerId = props.selectedCustomer.customerId;
		if (props?.selectedDateRange?.startDate) payload.startDate = props.selectedDateRange.startDate;
		if (props?.selectedDateRange?.endDate) payload.endDate = props.selectedDateRange.endDate;
		if (props?.selectedFilterValues && props.selectedFilterValues['PRODUCT_NAME_MULTIPLE'])
			payload.productIds = props.selectedFilterValues['PRODUCT_NAME_MULTIPLE'].map(
				(product: any) => product.productItemId,
			);
		if (props?.selectedFilterValues && props.selectedFilterValues['PRODUCT_GROUP_MULTIPLE'])
			payload.productGroups = props.selectedFilterValues['PRODUCT_GROUP_MULTIPLE'];
		if (props?.selectedFilterValues && props.selectedFilterValues['CABINET_TYPE'])
			payload.cabinetType = props.selectedFilterValues['CABINET_TYPE'];
		if (props?.selectedFilterValues && props.selectedFilterValues['INVOICE_ABW'])
			payload.abwCode = props.selectedFilterValues['INVOICE_ABW'];
		if (props?.selectedFilterValues && props.selectedFilterValues['SERIAL_NUMBER'])
			payload.serialNumber = props.selectedFilterValues['SERIAL_NUMBER'];
		if (props?.selectedFilterValues && props.selectedFilterValues['LOT_NUMBER'])
			payload.lot = props.selectedFilterValues['LOT_NUMBER'];

		props.transmitToSap(payload);
	};
	const handleSendingReminderEmail = () => {
		// Call send reminder e-mail API
		let payload: { [key: string]: any } = {};
		if (props?.selectedCustomer) payload.customerId = props.selectedCustomer.customerId;
		if (props?.selectedFilterValues && props.selectedFilterValues['PRODUCT_NAME_MULTIPLE'])
			payload.productIds = props.selectedFilterValues['PRODUCT_NAME_MULTIPLE'].map(
				(product: any) => product.productItemId,
			);
		if (props?.selectedFilterValues && props.selectedFilterValues['PRODUCT_GROUP_MULTIPLE'])
			payload.productGroups = props.selectedFilterValues['PRODUCT_GROUP_MULTIPLE'];
		if (props?.selectedFilterValues && props.selectedFilterValues['CABINET_TYPE'])
			payload.cabinetType = props.selectedFilterValues['CABINET_TYPE'];
		if (props?.selectedFilterValues && props.selectedFilterValues['INVOICE_ABW'])
			payload.abwCode = props.selectedFilterValues['INVOICE_ABW'];
		if (props?.selectedFilterValues && props.selectedFilterValues['SERIAL_NUMBER'])
			payload.serialNumber = props.selectedFilterValues['SERIAL_NUMBER'];
		if (props?.selectedFilterValues && props.selectedFilterValues['LOT_NUMBER'])
			payload.lot = props.selectedFilterValues['LOT_NUMBER'];

		props.sendReminderEmail(payload);
	};

	const handleMoveAbwToSelections = () => {
		// Set all invoices to "Ready" state
		let payload: PatchInvoiceReadyPayload = { filters: {} };
		let filters: PatchInvoiceReadyStatusFilterDto = {};

		if (props?.selectedCustomer) filters = { ...filters, customerId: props.selectedCustomer.customerId };
		if (props?.selectedDateRange?.startDate) filters = { ...filters, startDate: props.selectedDateRange.startDate };
		if (props?.selectedDateRange?.endDate) filters = { ...filters, endDate: props.selectedDateRange.endDate };
		if (props?.selectedFilterValues && props.selectedFilterValues['PRODUCT_NAME_MULTIPLE'])
			filters = {
				...filters,
				productIds: props.selectedFilterValues['PRODUCT_NAME_MULTIPLE'].map((product: any) => product.productItemId),
			};
		if (props?.selectedFilterValues && props.selectedFilterValues['PRODUCT_GROUP_MULTIPLE'])
			filters = { ...filters, productGroups: props.selectedFilterValues['PRODUCT_GROUP_MULTIPLE'] };
		if (props?.selectedFilterValues && props.selectedFilterValues['CABINET_TYPE'])
			filters = { ...filters, cabinetType: props.selectedFilterValues['CABINET_TYPE'] };
		if (props?.selectedFilterValues && props.selectedFilterValues['INVOICE_ABW'])
			filters = { ...filters, abwCode: props.selectedFilterValues['INVOICE_ABW'] };
		if (props?.selectedFilterValues && props.selectedFilterValues['SERIAL_NUMBER'])
			filters = { ...filters, serialNumber: props.selectedFilterValues['SERIAL_NUMBER'] };
		if (props?.selectedFilterValues && props.selectedFilterValues['LOT_NUMBER'])
			filters = { ...filters, lot: props.selectedFilterValues['LOT_NUMBER'] };

		payload.filters = filters;

		props.patchMakeAllInvoicesReady(payload);
		handleGetInvoiceRequests();
	};
	const handleHoldToggle = (isChecked: boolean, invoiceIndex: number) => {
		// Call set hold status route
		props.updateInvoiceRequestStatus({
			invoiceId: invoiceRequests[invoiceIndex].id,
			status: isChecked ? 'ON HOLD' : 'READY',
			onSuccess: () => {
				const newInvoiceRequest = { ...invoiceRequests[invoiceIndex] };
				newInvoiceRequest.status = isChecked ? 'ON HOLD' : 'READY';
				const newInvoiceRequestList = [...invoiceRequests];
				newInvoiceRequestList[invoiceIndex] = newInvoiceRequest;
				setInvoiceRequests(newInvoiceRequestList);
			},
		});
	};

	const lookUpInvoiceById = (iid: number) => {
		return invoiceRequests.find((invoice: any) => {
			return invoice.id == iid
		})
	}

	/*
	* toggle selection of this invoice for a Omit batch
	*/
	const handleOmitInvoiceCheckboxChange = (isChecked: boolean, invoiceIndex: number) => {
		const invoiceId = Number(invoiceRequests[invoiceIndex].id);
		const invoicesSelectedToOmitCopy: number[] = [...invoicesSelectedToOmit];
		const idCurIndex = invoicesSelectedToOmitCopy.indexOf(invoiceId)

		if (isChecked && idCurIndex === -1) { //add to list
			invoicesSelectedToOmitCopy.push(invoiceId);
		}
		else if (!isChecked && idCurIndex > -1) { //remove from list
			invoicesSelectedToOmitCopy.splice(idCurIndex, 1);
		}
		else {
			return;
		}

		console.log('ids selected are now:', invoicesSelectedToOmitCopy);

		setInvoicesSelectedToOmit(invoicesSelectedToOmitCopy);
	};

	const handleSubmitAbwSelectionsOnSuccess = () => {
		console.log('in handleSubmitAbwSelectionsOnSuccess');
		// Reload the table:
		handleGetInvoiceRequests();
	};

	/**
	 * Gets all the ABW selections from the table and sets them in state
	 * @returns {PatchInvoiceAbwDto[]} newAbwSelections
	 */
	const getNewAbwSelectionsFromTable = () => {
		const newAbwSelections = [...abwSelections];
		for (let i = 0; i < invoiceRequests.length; i++) {
			if (invoiceRequests[i].abwCode) {
				const abwSelectedIndex = newAbwSelections.findIndex(item => item.id === invoiceRequests[i].id);
				if (abwSelectedIndex === -1) {
					newAbwSelections.push({
						id: invoiceRequests[i].id,
						abwCode: invoiceRequests[i].abwCode,
					});
				} else {
					newAbwSelections[abwSelectedIndex].abwCode = invoiceRequests[i].abwCode;
				}
			}
		}
		setAbwSelections(newAbwSelections);
		return newAbwSelections;
	};
	const handleSubmitAbwSelections = () => {
		// It also set's them all to the "Ready" status and reloads the table
		props.patchInvoiceRequestAbwSelection({
			data: getNewAbwSelectionsFromTable(),
			onSuccess: handleSubmitAbwSelectionsOnSuccess,
		});
	};

	const handleGetCsv = (cols: any) => {
		const filter = { ...getFilter(), limit: 9999 };
		const exportCols = findUserSelectedColumns(cols);
		exportCols.push('transmitted');
		const params = constructEnqueueParams(
			'RNI_UIEXPORT_ABWSelectionReport',
			props.authUser?.email,
			filter,
			{ columnsRequested: exportCols },
			GetUserTimezone(props.properties),
		);

		props.sendOneTimeReportRequest(params);
	};

	const columnData = [
		{
			name: 'customerId',
			label: 'Customer #',
			change: true,
			viewColumns: true,
			display: true,
		},
		{
			name: 'productId',
			label: 'SKU',
			change: true,
			viewColumns: true,
			display: true,
		},
		{
			name: 'productName',
			label: 'Product',
			change: true,
			viewColumns: true,
			display: true,
		},
		{
			name: 'dispenseDate',
			label: 'Dispense Date',
			change: true,
			viewColumns: true,
			display: true,
			customBodyRender: (value?: any) => {
				return value ? DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT) : '';
			},
		},
		{
			name: 'ndc',
			label: 'NDC',
			change: true,
			viewColumns: true,
			display: true,
		},
		{
			name: 'lot',
			label: 'Lot',
			change: true,
			viewColumns: true,
			display: true,
		},
		{
			name: 'size',
			label: 'Size',
			change: true,
			viewColumns: true,
			display: false,
		},
		{
			name: 'assay',
			label: 'Assay',
			change: true,
			viewColumns: true,
			display: true,
		},
		{
			name: 'unitOfMeasure',
			label: 'Unit of Measure',
			change: true,
			viewColumns: true,
			display: false,
		},
		{
			name: 'serialNo',
			label: 'Serial #',
			change: true,
			viewColumns: true,
			display: true,
		},
		{
			name: 'rfidTag',
			label: 'RFID Tag',
			change: true,
			viewColumns: true,
			display: true,
			customBodyRenderLite: (dataIndex: number) => {
				const thisInvoice = invoiceRequests[dataIndex];
				if (thisInvoice.rfidTag) return thisInvoice.rfidTag.substring(thisInvoice.rfidTag.length - 6);
			},
		},
		{
			name: 'quantity',
			label: 'Qty',
			change: true,
			viewColumns: true,
			display: true,
		},
		{
			name: 'abwCode',
			label: (window.location.pathname.indexOf('/admin-invoices') === 0) ? 'ABW Selection' : 'Account',
			change: true,
			viewColumns: true,
			display: true,
			customBodyRenderLite: (dataIndex: number) => {
				
				const thisInvoice = invoiceRequests[dataIndex];
				if (thisInvoice.customerManualSplitBill !== 'Y') return 'Base Account';
				if (thisInvoice.customerManualSplitBill === 'Y' && thisInvoice.customerAutoSplitBill === 'Y') {
					return 'Auto Split Biller';
				}
				if (thisInvoice.omitted || thisInvoice.status === 'TRANSMITTED') {
					return thisInvoice.abwCode || 'Not Set';
				}
				const accessibleCustomers = Object.keys(props.authUser.claims); 
				if (props.authUser.claims[accessibleCustomers[0]]['invoices'] === 'OWNER') {
					return (
						<RadioGroup
							row
							aria-labelledby="abw-selection"
							name="abwSelection"
							value={invoiceRequests[dataIndex].abwCode}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								handleChangeAbw(dataIndex, event);
							}}
							style={{ minWidth: '100px' }}
						>
							<FormControlLabel
								value="A"
								control={<Radio style={{ maxWidth: '28px' }} />}
								label="A"
								labelPlacement="top"
								style={{ margin: '2px' }}
							/>
							<FormControlLabel
								value="B"
								control={<Radio style={{ maxWidth: '28px' }} />}
								label="B"
								labelPlacement="top"
								style={{ margin: '2px' }}
							/>
							<FormControlLabel
								value="W"
								control={<Radio style={{ maxWidth: '28px' }} />}
								label="W"
								labelPlacement="top"
								style={{ margin: '2px' }}
							/>
						</RadioGroup>
					);
				} else {
					return thisInvoice.abwCode || 'Not Set';
				}
			},
		},
		{
			name: 'status',
			label: (window.location.pathname.indexOf('/admin-invoices') === 0) ? 'Status/Omit' : 'Status',
			display: true,
			change: true,
			viewColumns: true,
			customBodyRenderLite: (dataIndex: number) => {
				const thisInvoice = invoiceRequests[dataIndex];
				const isAdminPage = (window.location.pathname.indexOf('/admin-invoices') === 0);
				const value = thisInvoice.status;

				if (value === 'TRANSMITTED') {
					return (
						value +
						' @ ' +
						DateTime.fromISO(thisInvoice.transmitted).toLocaleString(DateTime.DATETIME_SHORT)
					);
				}
				const holdInvoiceToggleLabel = {
					inputProps: { 'aria-label': 'Hold Invoice Toggle' },
				};
				return (
					<>
						<span>{value}</span>
						{isAdminPage && <span>&nbsp;</span>}
						

						{(!thisInvoice.omitted && !(thisInvoice.status === "OMITTED")) && isAdminPage && (
							<div style={{
								fontSize: 14
							}}>
								<Checkbox 
									onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
										handleOmitInvoiceCheckboxChange(event.target.checked, dataIndex);
									}}
									checked={invoicesSelectedToOmit.indexOf(thisInvoice.id) > -1}
								/>
								Omit
							</div>
						)}
							
						{(!thisInvoice.omitted && !(thisInvoice.status === "OMITTED")) && isAdminPage && (
							<Switch
								{...holdInvoiceToggleLabel}
								color="warning"
								checked={thisInvoice.status === 'ON HOLD'}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									handleHoldToggle(event.target.checked, dataIndex);
								}}
								icon={<PauseCircle />}
								title={
									thisInvoice.status === 'ON HOLD'
										? 'Unhold Invoice - Invoice will be ready to be sent to SAP'
										: 'Hold Invoice - Invoice will NOT be sent to SAP'
								}
							/>
						)}
					</>
				);
			},
		},
	]

	const getTableColumns = () => {
		return columnData.map((column: any, index: number) => {
			return {
				label: column.label,
				name: column.name,
				options: {
					filter: true,
					display: tableStatePersist ? tableStatePersist.columns[index].display : column.display,
					change: column.change,
					viewColumns: column.viewColumns,
					...(column.customBodyRender && { customBodyRender: column.customBodyRender }),
					...(column.customBodyRenderLite && { customBodyRenderLite: column.customBodyRenderLite }),
				},
			};
		});
	};

	return (
		<>
			<div>
				<Grid container>
					<Grid item xs={12}>
						<GlobalFiltersComponentContainer
							pageName={isAdminPage ? 'INVOICE_REQUESTS_ADMIN' : 'INVOICE_REQUESTS'}
							executeSearch={onSearchClick}
						/>
					</Grid>
				</Grid>
			</div>
			{invoiceRequests && invoiceRequests.length > 0 ? (
				<>
					<Grid item xs={12}>
						<MUIDataTable
							data={invoiceRequests}
							columns={getTableColumns()}
							options={{
								rowsPerPage: isAdminPage ? props.globalRowsPerPage : 10000,
								rowsPerPageOptions: isAdminPage ? dataList.PageLimitOptions.concat([200, 500, 800]) : [10000],
								onChangeRowsPerPage: (numberOfRows: number) => {
									props.setGlobalRowsPerPage(numberOfRows);
									setPageState({
										offset: pagination.page * numberOfRows,
										limit: numberOfRows,
										page: pagination.page,
									});
								},
								selectableRows: 'none',
								filter: false,
								search: false,
								serverSide: true,
								count: props.totalInvoiceRequests,
								onTableChange: (tableAction: any, tableState: any) => {
									switch (tableAction) {
										case 'changePage':
											if (tableState.page > pagination.page) {
												setPageState({
													offset: pagination.offset + props.globalRowsPerPage,
													limit: props.globalRowsPerPage,
													page: tableState.page,
												});
											} else if (tableState.page < pagination.page) {
												setPageState({
													offset: pagination.offset - props.globalRowsPerPage,
													limit: props.globalRowsPerPage,
													page: tableState.page,
												});
											}
											break;
										case 'viewColumnsChange':
											setTableStatePersist(tableState);
											break;
									}
								},
								onDownload: (buildHead: any, buildBody: any, cols: any, data: any) => {
									handleGetCsv(cols);
									return false;
								},
								onColumnSortChange: (changedColumn: string, direction: string) => {
									console.log('Column Changed: ', changedColumn, ' direction: ', direction);
									setOrderInvoiceRequestList(changedColumn + ' ' + direction);
									handleGetInvoiceRequests();
								},
								setRowProps: (row: any, dataIndex: any, rowIndex: any) => {
									const thisInvoice = invoiceRequests[dataIndex]; 

									if (thisInvoice && (thisInvoice.omitted || (thisInvoice.status === "OMITTED")) && isAdminPage) {
										return { style: { backgroundColor: '#4CCB17' } };
									}
								},
							}}
						/>
					</Grid>
					<Dialog open={openOmitDialog} onClose={handleOmitDialogClose}>
						<DialogTitle>
							Omit these invoice(s)?
						</DialogTitle>
						<DialogContent>
							<DialogContentText>
								Are you sure? This action can <b>not</b> be undone.
							</DialogContentText>
							<TableContainer component={Paper} style={{maxHeight: 500, overflow: 'scroll'}}>
								<Table aria-label="customized table">
									<TableHead>
										<TableRow>
											<TableCell>Customer ID</TableCell>
											<TableCell>SKU</TableCell>
											<TableCell>Lot</TableCell>
											<TableCell>Serial/Tag</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{invoicesSelectedToOmit.map((iid: any) => {
											const row = lookUpInvoiceById(iid)
											return <TableRow>
												<TableCell>{row.customerId}</TableCell>
												<TableCell>{row.productId}</TableCell>
												<TableCell>{row.lot}</TableCell>
												<TableCell>{`${row.serialNo || 'no serial'}/${row.rfidTag || 'no tag'}`}</TableCell>
											</TableRow>
										})}
									</TableBody>
								</Table>
							</TableContainer>
							{/* <Grid container spacing={1}>
								<Grid item xs={6}>
									{invoicesSelectedToOmit.length && (invoicesSelectedToOmit.length > 1 ?
										<span>Customer #s: {
											invoicesSelectedToOmit.map((iid, i) => {
												const val = lookUpInvoiceById(invoicesSelectedToOmit[i])?.customerId;
												return (i === invoicesSelectedToOmit.length-1) ? val : `${val}, `;
											})
										}
										</span>
										:
										<span>Customer #: {lookUpInvoiceById(invoicesSelectedToOmit[0])?.customerId}</span>
										)
									}			
								</Grid>
								<Grid item xs={6}>
									{invoicesSelectedToOmit.length && (invoicesSelectedToOmit.length > 1 ?
										<span>SKUs: {
											invoicesSelectedToOmit.map((iid, i) => {
												const val = lookUpInvoiceById(invoicesSelectedToOmit[i])?.productId;
												return (i === invoicesSelectedToOmit.length-1) ? val : `${val}, `;
											})
										}
										</span>
										:
										<span>SKU: {lookUpInvoiceById(invoicesSelectedToOmit[0])?.productId}</span>
										)
									}
								</Grid>
								<Grid item xs={6}>
									{invoicesSelectedToOmit.length && (invoicesSelectedToOmit.length > 1 ?
										<span>Lot #s: {
											invoicesSelectedToOmit.map((iid, i) => {
												const val = lookUpInvoiceById(invoicesSelectedToOmit[i])?.lot;
												return (i === invoicesSelectedToOmit.length-1) ? val : `${val}, `;
											})
										}
										</span>
										:
										<span>Lot #: {lookUpInvoiceById(invoicesSelectedToOmit[0])?.lot}</span>
										)
									}
								</Grid>
								<Grid item xs={6}>
									{invoicesSelectedToOmit.length && (invoicesSelectedToOmit.length > 1 ?
										<span>Serial #s: {
											invoicesSelectedToOmit.map((iid, i) => {
												const val = lookUpInvoiceById(invoicesSelectedToOmit[i])?.serialNo;
												return (i === invoicesSelectedToOmit.length-1) ? val : `${val}, `;
											})
										}
										</span>
										:
										<span>Serial #: {lookUpInvoiceById(invoicesSelectedToOmit[0])?.serialNo}</span>
										)
									}
								</Grid>
							</Grid> */}
							<Grid container style={{marginTop: 15}}>
								<Grid item xs={omitConfirmationObj.reason === 'RETURN' ? 6 : 12} style={{padding: 5}}>
									<TextField
										autoFocus
										margin="dense"
										id="omittalReason"
										label="Omittal Reason"
										select
										fullWidth
										required
										value={omitConfirmationObj.reason}
										onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
											const newObj = { ...omitConfirmationObj };
											newObj.reason = event.target.value;
											if (newObj.reason) {
												newObj.error = false;
												newObj.errorHelpText = '';
											} else {
												newObj.error = true;
												newObj.errorHelpText = 'You must provide a reason for omitting';
											}
											setOmitConfirmationObj(newObj);
										}}
										error={omitConfirmationObj.error}
										helperText={omitConfirmationObj.errorHelpText}
									>
										<MenuItem value={''}>Choose</MenuItem>
										<MenuItem value={'REVERT'}>Revert</MenuItem>
										<MenuItem value={'RETURN'}>Return</MenuItem>
										<MenuItem value={'ERROR'}>Error</MenuItem>
										<MenuItem value={'OTHER'}>Other</MenuItem>
									</TextField>
								</Grid>
								{omitConfirmationObj.reason === 'RETURN' && <Grid item xs={6} style={{ padding: 5, marginTop: 12}}>
									<TextField
										label="RGA No"
										variant="outlined"
										fullWidth
										value={rga || ''}
										error={rgaError}
										helperText={rgaError ? 'An Rga Number is required' : ''}
										required
										onChange={event => {
											setRgaError(false)
											setRga(event.target.value);
										}}
									/>
								</Grid>}
								<Grid item xs={12}>						
									<TextField
										margin="dense"
										id="omittalNote"
										label="Optional Omittal Note"
										type="text"
										fullWidth
										multiline
										minRows={2}
										value={invoiceRequests[omitDialogInvoiceIndex].omittedNote}
										onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
											const newObj = { ...omitConfirmationObj };
											newObj.note = event.target.value;
											setOmitConfirmationObj(newObj);
										}}
									/>
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleOmitDialogClose}>Cancel</Button>
							<LoadingButton
								color="error"
								onClick={() => {
									setLoadingOmitted(true);

									if (!omitConfirmationObj.reason) {
										const newObj = { ...omitConfirmationObj };
										newObj.error = true;
										newObj.errorHelpText = 'You must provide a reason for omitting';
										setOmitConfirmationObj(newObj);
										setLoadingOmitted(false);
										return;
									}

									if (omitConfirmationObj.reason === 'RETURN' && !rga) {
										setRgaError(true)
										setLoadingOmitted(false);
										return;
									}

									if (!invoicesSelectedToOmit.length) {
										const newObj = { ...omitConfirmationObj };
										newObj.error = true;
										newObj.errorHelpText = 'You must select at least one invoice to omit';
										setOmitConfirmationObj(newObj);
										setLoadingOmitted(false);
										return;
									}

									//update list with the updated object for each invoice that was omitted
									const newInvoiceRequestList = [...invoiceRequests];

									invoicesSelectedToOmit.forEach((invId: any) => {
										const invIndex = newInvoiceRequestList.findIndex((inv: any) => {
											return inv.id === invId;
										});

										newInvoiceRequestList[invIndex] = updateInvoiceInUI(newInvoiceRequestList[invIndex]);
									});

									props.patchInvoiceRequestOmittalBulk({
										invoiceIds: invoicesSelectedToOmit,
										omitted: true,
										omittedReason: omitConfirmationObj.reason,
										omittedNote: omitConfirmationObj.note,
										rgaNumber: rga || undefined,
										onSuccess: () => {
											setInvoiceRequests(newInvoiceRequestList);
											setLoadingOmitted(false);
											handleOmitDialogClose();
										},
									});

									//clear checkboxes
									setInvoicesSelectedToOmit([]);

									function updateInvoiceInUI(invObj: any) {
										const newInvoiceRequestObj = {
											...invObj,
										};

										newInvoiceRequestObj.omitted = true;
										newInvoiceRequestObj.omittedReason = omitConfirmationObj.reason;
										newInvoiceRequestObj.omittedNote = omitConfirmationObj.note;
										newInvoiceRequestObj.status = 'OMITTED';

										return newInvoiceRequestObj;
									}
								}}
								loading={loadingOmitted}
								variant="contained"
							>
								{<DeleteForeverIcon />} Omit
							</LoadingButton>
						</DialogActions>
					</Dialog>
				</>
			) : (
				<EmptyContent message="Select the filter criteria above to view invoice request information." />
			)}
			<div style={{ paddingTop: '10px' }} />
			{isAdminPage && (
				<Grid container>
					<Grid item xs={8} lg={9}>
						<Grid container spacing={1}>
							<Grid item>
								<Button
									onClick={() => setConfirmSendReminderEmailOpen(true)}
									variant="outlined"
									title="Sends a reminder e-mail to customers to select the appropriate account A, B or W."
								>
									Send ABW Reminder E-mail
								</Button>
							</Grid>
							<Grid item>
								<Button
									onClick={() => setConfirmMoveInvoicesToReadyOpen(true)}
									variant="outlined"
									title='Sets all ABW selectable invoices to "Ready" status'
								>
									Update Pending ABW to Ready
								</Button>
							</Grid>
							<Grid item>
								<Button
									onClick={() => setConfirmSaveAbwSelectionsOpen(true)}
									variant="outlined"
									title="Saves all of your ABW selections"
								>
									Save ABW Selections
								</Button>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={4} lg={3}>
						<Box display="flex" justifyContent="flex-end">
							<Button
								variant="contained"
								onClick={() => setConfirmSubmitAllInvoicesOpen(true)}
								title='Submit all invoices in the "Ready" status'
								style={{
									fontSize: 11,
									lineHeight: '12px'
								}}
							>
								Send All Ready Invoices
							</Button>
							{isAdminPage && (
								<Button
									variant="contained"
									onClick={() => handleBulkOmitDialogOpen()}
									title="Omit All Checked Invoices"
									style={{
										marginLeft: 10,
										fontSize: 11,
										lineHeight: '12px'
									}}
								>
									Omit All Checked Invoices
								</Button>
							)}
						</Box>
					</Grid>
				</Grid>
			)}
			{!isAdminPage && (
				<Grid container>
					<Grid item xs={8} lg={9}></Grid>
					<Grid item xs={4} lg={3}>
						<Box display="flex" justifyContent="flex-end">
							<Button
								onClick={() => setConfirmSaveAbwSelectionsOpen(true)}
								variant="contained"
								title="Saves all of your ABW selections"
							>
								Save ABW Selections
							</Button>
						</Box>
					</Grid>
				</Grid>
			)}

			<div>
				<ConfirmDialog
					open={confirmSubmitAllInvoicesOpen}
					handleClose={() => setConfirmSubmitAllInvoicesOpen(false)}
					title="Please Confirm"
					content="This will send all invoices in the ready state to SAP.  This can not be undone. Are you sure?"
					handleYes={() => {
						handleSendAllToSap();
						setConfirmSubmitAllInvoicesOpen(false);
					}}
				/>
				<ConfirmDialog
					open={confirmSendReminderEmailOpen}
					handleClose={() => setConfirmSendReminderEmailOpen(false)}
					title="Please Confirm"
					content="This will send an e-mail to customers to select an A, B or W account for all pending invoices not yet ready. Are you sure?"
					handleYes={() => {
						handleSendingReminderEmail();
						setConfirmSendReminderEmailOpen(false);
					}}
				/>
				<ConfirmDialog
					open={confirmMoveInvoicesToReadyOpen}
					handleClose={() => setConfirmMoveInvoicesToReadyOpen(false)}
					title="Please Confirm"
					content='This will set all ABW selectable invoices to the "Ready" status. Are you sure?'
					handleYes={() => {
						handleMoveAbwToSelections();
						setConfirmMoveInvoicesToReadyOpen(false);
					}}
				/>
				<ConfirmDialog
					open={confirmSaveAbwSelectionsOpen}
					handleClose={() => setConfirmSaveAbwSelectionsOpen(false)}
					title="Are you sure?"
					content="Are you sure? By selecting YES, your ABW selections will be saved and this cycle’s invoice will be set to READY status."
					handleYes={() => {
						handleSubmitAbwSelections();
						setConfirmSaveAbwSelectionsOpen(false);
					}}
				/>
			</div>
			{isDev && <pre>{JSON.stringify(invoiceRequests, null, 2)}</pre>}
		</>
	);
};

const mapStateToProps = (state: any) => {
	return {
		invoiceRequests: state.invoices.invoiceRequests ? state.invoices.invoiceRequests.results : [],
		totalInvoiceRequests: state.invoices.invoiceRequests ? state.invoices.invoiceRequests.total_record : 0,
		authUser: state.user && state.user.authUser && state.user.authUser.record ? state.user.authUser.record : null,
		globalRowsPerPage: state.ui.globalRowsPerPage ? state.ui.globalRowsPerPage : 10,
		selectedCustomer: state.ui.selectedCustomer,
		selectedDateRange: state.ui.selectedDateRange,
		selectedFilterValues: state.ui.selectedFilterValues,
		properties: Array.isArray(state.user?.authUser?.record?.properties) ? state.user.authUser.record.properties : [],
		selectedTransmittalDate: state.ui.selectedTransmittalDate,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	getInvoiceRequests: (filters: any[], ccPayableOnly: boolean = false) =>
		dispatch(InvoicesActions.getInvoiceRequests(filters)),
	setGlobalRowsPerPage: (rowsPerPage: number) => dispatch(UIAction.setGlobalRowsPerPage(rowsPerPage)),
	patchInvoiceRequestAbwSelection: (payload: PatchInvoiceAbwPayload) =>
		dispatch(InvoicesActions.patchInvoiceRequestAbwSelection(payload)),
	patchInvoiceRequestOmittal: (payload: PatchInvoiceOmitDto) =>
		dispatch(InvoicesActions.patchInvoiceRequestOmittal(payload)),
	patchInvoiceRequestOmittalBulk: (payload: any) =>
		dispatch(InvoicesActions.patchInvoiceRequestOmittalBulk(payload)),
	patchMakeAllInvoicesReady: (payload: PatchInvoiceReadyPayload) =>
		dispatch(InvoicesActions.patchMakeAllInvoicesReady(payload)),
	sendReminderEmail: (payload: SendAbwReminderEmail) => dispatch(InvoicesActions.sendAbwReminderEmail(payload)),
	transmitToSap: (payload: TransmitToSapRequest) => dispatch(InvoicesActions.transmitRequestsToSap(payload)),
	updateInvoiceRequestStatus: (payload: PatchInvoiceStatus) => dispatch(InvoicesActions.updateInvoiceStatus(payload)),
	sendOneTimeReportRequest: (params: any) => dispatch(UserActions.sendOneTimeReport(params)),
	disableSerialNumberFilter: (disabled: boolean) => dispatch(UIAction.disableSerialNumberFilter(disabled)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceRequests);
