import * as React from 'react';
import { Paper } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import { globalColors } from '../../hooks/styles';
import {ChartSettings, FulfilledResult} from './dashboard.types'
import { ButtonFilter, CornerMenuFilter, SelectMenuFilter } from './dashboard-charts/dashboard-filter';
import DashboardStackedBarChart from './dashboard-charts/dashboard-stackedbar-graph.chart';
import DashboardTableChart from './dashboard-charts/dashboard-table.chart';

interface DashboardCardProps {
  itemSetting: ChartSettings;
  record: FulfilledResult;
  getCardStatusTotals: Function;
  handleOpen: Function;
  setChartRecords: Function;
  setChartType: Function;
  setChartSettings: Function;
  dashboardStyling: any;
}

const Card = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  alignItems: 'center',
  alignContent: 'center'
}));

export const DashboardCard: React.FC<any> = (props: DashboardCardProps) => {

  const cardTotals = props.getCardStatusTotals(props.record);
  const [cardDisplay, setCardDisplay] = React.useState<number>(cardTotals.display)
  const [displayedFilter, setDisplayedFilter] = React.useState<any|null>(null)

  const cardSettings: { [key: string]: any } = {};
  if (cardTotals.status === 'Warning') cardSettings.backgroundColor = globalColors.WARNING_YELLOW;
  if (cardTotals.status === 'Fail') cardSettings.backgroundColor = globalColors.TABLE_WARNING;

  if (props.itemSetting.modalPopUp) {
      cardSettings.cursor = 'pointer';
      return (
          // Chart associated with summary
          <Card sx={cardSettings} onClick={() => {
              props.handleOpen();
              props.setChartRecords(props.record.records || []);
              props.setChartType(props.itemSetting.chartType);
              props.setChartSettings(props.itemSetting);
          }
          } elevation={4}>
              {props.itemSetting.displayName}
              <div className={props.dashboardStyling.cardItem}>
                  {cardTotals.display}
              </div>
          </Card>
      )
  }

  const applyFilter = (filterConfig: any, filterValue: any) => {
    filterValue = filterValue === 'all' ? null : filterValue
    let filteredTotals = props.record.records?.filter((record: any) => {
      return record[filterConfig.field] === filterValue ? true : false
    }).length
    if (!filterValue) {
      filteredTotals = cardTotals.display
    }
    setCardDisplay(filteredTotals || 0)
    setDisplayedFilter(filterValue)
  }

  const getCardContent = () => {
    if (!props.itemSetting.modalPopUp && props.itemSetting.chartType === 'table') {
      return (
        <Card elevation={4}>
          <div className={props.dashboardStyling.cardItem}>
            <DashboardTableChart records={props.record.records} settings={props.itemSetting} />
          </div>
        </Card>
      )
    }
    if (!props.itemSetting.modalPopUp && props.itemSetting?.chartType === 'stackedBar') {
      return (
        <Card elevation={4}>
          <div className={props.dashboardStyling.cardItem}>
            <DashboardStackedBarChart records={props.record.records} settings={props.itemSetting} />
          </div>
        </Card>
      )
    }
    return (
      <Card sx={cardSettings} elevation={4}>
        {!props.itemSetting.chartType && props.itemSetting.displayName}
        {props.itemSetting.filters?.map((filter: any) => {
          if (filter.type === 'button') {
            return <ButtonFilter
              cardName={props.itemSetting.summaryName} 
              filter={filter} 
              applyFilter={applyFilter}
            />
          }
          if (filter.type === 'selectMenu') {
            return <SelectMenuFilter 
              cardName={props.itemSetting.summaryName} 
              filter={filter} 
              applyFilter={applyFilter}
            />
          }
          if (filter.type === 'cornerMenu') {
            return <CornerMenuFilter 
              cardName={props.itemSetting.summaryName} 
              filter={filter} 
              applyFilter={applyFilter}
            />
          }
        })}
        <div className={props.dashboardStyling.cardItem}>
          {cardDisplay}
          {displayedFilter && 
            <div 
              style={{
                padding: '6px',
                cursor: 'pointer',
                backgroundColor: 'lightgrey',
                color: 'black',
                fontSize: 10,
                border: 'none',
              }}
            >
              {displayedFilter}
            </div>
          }
        </div>
      </Card>
    )
  }

  // Default card item
  return getCardContent()

}