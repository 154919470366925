import React from 'react';
import { connect } from 'react-redux';
import OrdersComponent from './orders.component';
import { TenantsActions, UIAction, OrderActions, ProductActions, UserActions } from '../../redux/actions';
import { Grid } from '@material-ui/core';
import { CommonStyles } from '../../hooks/styles';
import GlobalFiltersComponentContainer from '../../components/globalFiltersComponent';


	const OrdersContainer: React.FC<any> = (props: any) => {
		const classes = CommonStyles();
		const [pagination, setPagination] = React.useState({ offset: 0, limit: props.globalRowsPerPage, page: 0 } as any);
		const [sortOrder, setSortOrder] = React.useState('');
		const [tabView, setTabView] = React.useState<string>('restockOrders');

		const executeSearch = () => {
			setPagination({ offset: 0, limit: props.globalRowsPerPage, page: 0 });
		};

		return (
			<div>
					<div className={classes.searchWrap}>
						<Grid item xs={12}>
							{tabView === 'restockOrders' && <GlobalFiltersComponentContainer
									pageName={"RESTOCK_ORDERS"}
									executeSearch={executeSearch}
								/>}
								{tabView === 'orderRequests' && 
								<GlobalFiltersComponentContainer 
									pageName="ORDER_REQUESTS" 
									executeSearch={executeSearch} 
								/>}
						</Grid>
					</div>
					<div>
					<OrdersComponent
						tabView={tabView}
						setTabView={setTabView}
						pagination={pagination} 
						setPagination={setPagination}
						page={'customerOrders'}
						sortOrder={sortOrder}
						setSortOrder={setSortOrder}
					/>
					</div>
			</div>
		)
	}

const mapStateToProps = (state: any) => ({
	orders: state.orders.orderList ? state.orders.orderList.result : [],
	groupedOrders: state.orders.groupedOrderList ? state.orders.groupedOrderList.result : [],
	totalOrders: state.orders.orderList ? state.orders.orderList.total_record : 0,
	totalGroupedOrders: state.orders.groupedOrderList ? state.orders.groupedOrderList.total_record : 0,
	authUser: state.user.authUser,
	selectedCustomer: state.ui.selectedCustomer,
	tenants: state.tenants?.tenantsList?.result || [],
	products: state.product.productList || {
		result: [],
		total: 0,
	},
	autoCompleteProducts: state.product.autoCompleteList || [],
	statesList: state.cabinet.stateList ? state.cabinet.stateList.result : [],
	selectedFilterValues: state.ui.selectedFilterValues,
	selectedDateRange: state.ui.selectedDateRange,
	globalRowsPerPage: state.ui.globalRowsPerPage ? state.ui.globalRowsPerPage : 10,
	properties: Array.isArray(state.user?.authUser?.record?.properties) ? state.user.authUser.record.properties : [],
});

const mapDispatchToProps = (dispatch: any) => ({
	searchTenants: (filter: any) => dispatch(TenantsActions.getTenantList(filter)),
	getOrders: (payload: any) => dispatch(OrderActions.getOrderList(payload)),
	getGroupedOrders: (payload: any) => dispatch(OrderActions.getGroupedOrderList(payload)),
	resetTenants: () => dispatch(TenantsActions.clearTenantsList(true)),
	setSelectedCustomer: (customer: any) => dispatch(UIAction.setSelectedCustomer(customer)),
	getProducts: (filter: any) => dispatch(ProductActions.getProductList(filter)),
	getAutoCompleteProducts: (filter: any) => dispatch(ProductActions.getProductAutoCompleteList(filter)),
	setGlobalRowsPerPage: (rowsPerPage: number) => dispatch(UIAction.setGlobalRowsPerPage(rowsPerPage)),
	sendOneTimeReportRequest: (params: any) => dispatch(UserActions.sendOneTimeReport(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersContainer);
