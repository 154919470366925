import * as React from 'react';

import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import useHotjar from 'react-use-hotjar';
import config from '../config';
import InventoryManagementPage from '../pages/inventory-management';
import ParUsagePage from '../pages/par-usage';
import CustomerList from '../pages/customer/list';
import Users from '../pages/users';
import CustomerDetailsPage from '../pages/customer/details';
import CustomerDetailsSelector from '../pages/customer/details/customer-details-selector';
import TransactionLogPage from '../pages/reports/transaction-log';
import CycleCountsPage from '../pages/reports/cycle-counts';
import InventoryHistoryPage from '../pages/reports/inventory-history';
import DispensedProductsPage from '../pages/reports/dispensed-products';
import WarehouseShipmentsPage from '../pages/reports/warehouse-shipments';
import Cabinets from '../pages/cabinets';
import CabinetTemperature from '../pages/cabinet-temperature';
import InvoiceList from '../pages/invoice';
import ProcessAuth from '../pages/login/ProcessAuth';
import cabinetDetailsComponent from '../pages/cabinets/cabinet-details.component';
import Telemetry from '../pages/telemetry';
import paymentConfirmation from '../pages/invoice/payment-confirmation';
import Products from '../pages/products';
import Orders from '../pages/orders';
import CustomerProfile from '../pages/customerProfile';
import Incidents from '../pages/incidents';
import PersonasManagement from '../pages/personas-management';
import SuspendedTransactions from '../pages/suspended-transactions';
import Shipments from '../pages/shipments';
import Logout from '../pages/logout';
import SessionTimeout from '../pages/logout/session-timeout';
import AuthError from '../pages/logout/auth-error';
import HelpVideosPage from '../pages/help-videos';
import ScheduledReports from '../pages/scheduled-reports';
import SupportPage from '../pages/support';
import FourZeroFour from '../pages/login/404';
import { MuiThemeProvider, CssBaseline } from '@material-ui/core';
import { themeNames } from '../hooks/styles/muiTheme';
import { getMuiTheme } from '../hooks/styles';
import AuditLogs from '../pages/audit-logs';
import CabinetPropertySettings from '../pages/cabinet-property-settings';
import { default as GlobalSnackbar } from '../components/snackbar';
import { initUserType } from '../pages/users/users.helper';
import RestockOrderDetail from '../pages/reports/restock-order-detail';
import Tags from '../pages/tags';
import AbwSelection from '../pages/invoice/abw-selection.component';
import Dashboard from '../pages/dashboard';
import CustomerInvoices from '../pages/customer-invoices/invoice-history';
import OrderManagement from '../pages/order-management';
import DeviceConfigManagement from '../pages/device-config-management';
import InvoiceHistory from '../pages/customer-invoices/invoice-history';
import CCPayable from '../pages/customer-invoices/cc-payable';

const forwardSubdomainMap: any = {
	'dev-app': 'dev-track.rightnowinventory.com',
	'dev-ops': 'dev-track.rightnowinventory.com',
	'dev-internal': 'dev-track.rightnowinventory.com',
	'qa-app': 'qa-track.rightnowinventory.com',
	'qa-ops': 'qa-track.rightnowinventory.com',
	app: 'track.rightnowinventory.com',
	ops: 'track.rightnowinventory.com',
};

const forwardRoot = () => {
	const url = sessionStorage.getItem('url');
	return <Redirect to={!url || url === '/' ? '/dashboard' : `${url}`} />;
};

/*
 * list the root of all valid routes here
 * so we can test for a definite 404 before user auth, etc
 */
const allFlexibleRoutes = [
	//these routes may have 1-N params after the root
	'/inventory-management',
	'/par-levels',
	'/users',
	'/telemetry',
	'/incidents',
	'/cabinet-list',
	'/cabinet-temperature',
	'/device-config-management',
	'/product-list',
	'/restock-orders',
	'/shipments',
	'/customer-list',
	'/cycle-counts',
	'/transaction-log',
	'/inventory-history',
	'/dispensed-products',
	'/warehouse-shipments',
	'/cabinet-details',
	'/customer-details',
	'/suspended-transactions',
	'/profile',
	'/audit-logs',
	'/save-search',
	'/restock-order-detail',
	'/tags',
	'/admin-invoices',
	'/abw-selection',
	'/invoice-history',
	'/cc-payable',
	'/order-management',
	'/dashboard',
	'/order-requests',
];

const allExactRoutes = [
	//these routes accept no params
	'/personas',
	'/cabinet-property-settings',
	'/logout',
	'/session-timeout',
	'/auth-error',
	'/invoice-payment-confirmation',
	'/help',
	'/support',
	'/scheduled-reports',
	'/accounts',
];

const isFourOhFour = () => {
	const path = window.location.pathname;

	if (path === '' || path === '/') {
		return false;
	}

	//valid flex routes are:
	// [any of allFlexibleRoutes exactly]
	// OR [any of allFlexibleRoutes] + '/'
	// OR [any of allFlexibleRoutes] + '/' + [anything]
	const isNotFlexRoute = !allFlexibleRoutes.some(fr => {
		return path === fr || path.indexOf(fr + '/') === 0;
	});

	const isNotExactRoute = !allExactRoutes.some(r => {
		return path === r || path === r + '/';
	});

	return isNotFlexRoute && isNotExactRoute;
};

const Routes = (props: any) => {

	const isAdmin = (role?: string) => {
		if (!props.authUser) {
			return false
		}
		if (initUserType(props.authUser.record.claims) === 'admin') {
			if (!role) {
				return true
			}
			return props.authUser.record.claims['ALL'][role] ? true : false
		}
		return false
	};

	const { initHotjar, identifyHotjar } = useHotjar();

	React.useEffect(() => {
		if (config.hotjar) {
			initHotjar(config.hotjar.trackingId, 6, false, console.log);
		}
	}, [initHotjar]);

	React.useEffect(() => {
		if (props.authUser && config.hotjar) {
			identifyHotjar(props.authUser.record.email, props.authUser.record, console.log);
		}
	}, [props.authUser]);

	const subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
	if (subdomain && forwardSubdomainMap[subdomain]) {
		window.location.href = `https://${forwardSubdomainMap[subdomain]}${window.location.pathname}`;
		return <Router></Router>;
	}

	return (
		<MuiThemeProvider theme={getMuiTheme(props.selectedTheme || themeNames.LIGHT)}>
			<CssBaseline>
				<GlobalSnackbar />
				<Router>
					{isFourOhFour() && <Route component={FourZeroFour} />}
					{!isFourOhFour() && (
						<>
							<ProcessAuth />
							<Switch>
								{/* Protected Routes */}
								{props.authUser && <Route exact path="/" component={forwardRoot} />}
								{props.authUser && <Route exact path="/dashboard/:customerId?" component={Dashboard} />}
								{props.authUser && (
									<Route exact path="/inventory-management/:customerId?" component={InventoryManagementPage} />
								)}
								{props.authUser && <Route exact path="/par-levels/:customerId?" component={ParUsagePage} />}
								{props.authUser && <Route exact path="/telemetry/:customerId?" component={Telemetry} />}
								{props.authUser && <Route exact path="/incidents/:customerId?" component={Incidents} />}
								{props.authUser && <Route exact path="/cabinet-list/:customerId?" component={Cabinets} />}
								{props.authUser && (
									<Route exact path="/cabinet-temperature/:customerId?" component={CabinetTemperature} />
								)}
								{props.authUser && <Route exact path="/product-list/:customerId?" component={Products} />}
								{props.authUser && <Route exact path="/restock-orders/:customerId?" component={Orders} />}
								{props.authUser && <Route exact path="/shipments/:customerId?" component={Shipments} />}
								{props.authUser && <Route exact path="/cycle-counts/:customerId?" component={CycleCountsPage} />}
								{props.authUser && <Route exact path="/transaction-log/:customerId?" component={TransactionLogPage} />}
								{props.authUser && (
									<Route exact path="/inventory-history/:customerId?" component={InventoryHistoryPage} />
								)}
								{props.authUser && (
									<Route exact path="/dispensed-products/:customerId?" component={DispensedProductsPage} />
								)}
								{props.authUser && (
									<Route exact path="/warehouse-shipments/:customerId?" component={WarehouseShipmentsPage} />
								)}
								{props.authUser && (
									<Route exact path="/cabinet-details/:customerId/:cabinetId" component={cabinetDetailsComponent} />
								)}
								{props.authUser && <Route exact path="/invoice-payment-confirmation" component={paymentConfirmation} />}
								{props.authUser && <Route exact path="/customer-details/:customerId" component={CustomerDetailsPage} />}
								{props.authUser && <Route exact path="/accounts" component={CustomerDetailsSelector} />}
								{props.authUser && <Route exact path="/profile/:customerId?" component={CustomerProfile} />}
								{props.authUser && <Route exact path="/help" component={HelpVideosPage} />}
								{props.authUser && <Route exact path="/support" component={SupportPage} />}
								{props.authUser && <Route exact path="/scheduled-reports" component={ScheduledReports} />}
								{props.authUser && (
									<Route exact path="/abw-selection/:customerId?/:autoload?" component={AbwSelection} />
								)}
								{props.authUser && (
									<Route exact path="/invoice-history/:customerId?" component={InvoiceHistory} />
								)}
								{props.authUser && (
									<Route exact path="/cc-payable/:customerId?" component={CCPayable} />
								)}

								{isAdmin('inventory') && (
									<Route exact path="/suspended-transactions/:customerId?" component={SuspendedTransactions} />
								)}
								{isAdmin('users') && <Route exact path="/users/:customerId?" component={Users} />}
								{isAdmin() && <Route exact path="/audit-logs/:customerId?" component={AuditLogs} />}
								{isAdmin('tenants') && <Route exact path="/customer-list/:customerId?" component={CustomerList} />}
								{isAdmin('cabinets') && <Route exact path="/cabinet-property-settings" component={CabinetPropertySettings} />}
								{isAdmin('personas') && <Route exact path="/personas" component={PersonasManagement} />}
								{isAdmin('orders') && <Route exact path="/restock-order-detail/:orderId" component={RestockOrderDetail} />}
								{isAdmin('inventory') && <Route exact path="/tags/:customerId?" component={Tags} />}
								{isAdmin('invoices') && <Route exact path="/admin-invoices/:customerId?" component={InvoiceList} />}
								{isAdmin('orders') && <Route exact path="/order-management/:customerId?" component={OrderManagement} />}
								{isAdmin('device_config') && <Route exact path="/device-config-management/:customerId?" component={DeviceConfigManagement} />}
								{/* "backup" 404 for malformed params on an otherwise valid url,
                or a logged-in but non-admin user hitting an admin route */}
								{props.authUser && <Route component={FourZeroFour} />}

								{/* logic in ProcessAuth excludes these paths from the login check,
                so non logged-in users can reach them here rather than be redirected to Ping login */}
								<Route exact path="/logout" component={Logout} />
								<Route exact path="/session-timeout" component={SessionTimeout} />
								<Route exact path="/auth-error" component={AuthError} />
							</Switch>
						</>
					)}
				</Router>
			</CssBaseline>
		</MuiThemeProvider>
	);
};

const mapStateToProps = (state: any) => ({
	authUser: state.user.authUser,
	selectedTheme: state.user.selectedTheme,
});

export default connect(mapStateToProps)(Routes);
