export const getExpiringInventorySeries = (dataSet: any[]) => {
  if (dataSet.length === 0) {
    return []
  }
  let groupedByProductObj: any = {}
  dataSet.forEach((record: any) => {
    if (!groupedByProductObj[record.productGroup]) {
      groupedByProductObj[record.productGroup] = {
        "90": 0,
        "60": 0,
        "30": 0,
        "7": 0,
        "EXPIRED": 0
      }
    }
    groupedByProductObj[record.productGroup][record.expiringWithinDays] += record.totalInventory;
    [90,60,30,7].forEach((numDays: number) => {
      if (record.expiringWithinDays === 'EXPIRED') {
        return
      }
      if (numDays > record.expiringWithinDays) {
        groupedByProductObj[record.productGroup][numDays] += record.totalInventory;
      }
    })

  })

  const series = Object.keys(groupedByProductObj).map((productGroup: string) => {
    return {
      name: productGroup,
      data: [
        groupedByProductObj[productGroup]["90"],
        groupedByProductObj[productGroup]["60"],
        groupedByProductObj[productGroup]["30"],
        groupedByProductObj[productGroup]["7"],
        groupedByProductObj[productGroup]["EXPIRED"]
      ]
    }
  })

  return series
}