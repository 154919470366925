import React from 'react';
import { connect } from 'react-redux';
import BasePage from '../common/base';
import { Container, Grid, Paper } from '@material-ui/core';
import { DashboardStyles, SideBarStyles } from '../../hooks/styles';
import { GetUserTimezone, UserTimezoneFormat, valueMapper } from '../../hooks/functions';
import { Link } from 'react-router-dom';
import { CabinetsContainer } from '../cabinets/cabinets.container';
import { DashboardContainer } from './dashboard.container';
import { DashboardExport } from './dashboard-export';
import { UserActions } from '../../redux/actions';

const DashboardComponent: React.FC<any> = (props: any) => {
	const classes = DashboardStyles();
	const navStyles = SideBarStyles();

	const printRef = React.useRef(null);

	const currentUser = props.authUser.record;
	const roles = currentUser && currentUser.claims ? currentUser.claims[Object.keys(currentUser.claims)[0]] : null;

	const getGreeting = () => {
		let greeting: string = `Hello, ${currentUser.firstName}!`;
		const greetingsArray = [
			[22, `Working late, ${currentUser.firstName}?`],
			[18, `Good evening, ${currentUser.firstName}!`],
			[12, `Good afternoon, ${currentUser.firstName}!`],
			[3, `Good morning, ${currentUser.firstName}!`],
			[0, `Working late, ${currentUser.firstName}?`],
		];
		const currentDate = new Date(new Date().toLocaleString('en-US', { timeZone: GetUserTimezone(props.userProperties) }));
		const hr: any = currentDate.getHours();
		for (var i = 0; i < greetingsArray.length; i++) {
			if (hr >= greetingsArray[i][0]) {
				greeting = greetingsArray[i][1] as string;
				break;
			}
		}
		return greeting;
	};

	const getDashboardToDisplay = () => {

		const dashboardRoles = ['dashboard_operations', 'dashboard_services', 'dashboard_customer'];
		const dashboardRole = dashboardRoles.find(el => Object.keys(roles).includes(el));

		const dashboardValues = props.dashboardValues || [];
		// const rejected = dashboardValues?.rejected; // Leaving in case we want to handle rejected responses
		const fulfilled = dashboardValues?.fulfilled;

		if (dashboardRole) {
			return (
				<div>
					<div style={{ margin: '10px 0' }}>
						<div className={classes.lastLoginMsg}>{`Data as of: ${UserTimezoneFormat(
							props.userProperties,
							dashboardValues.dateOfData,
						)}`}
						</div>
						<div className={classes.dashboardExportActions}>
							<DashboardExport
								dashboardRole={dashboardRole} 
								printRef={printRef} 
								csvExport={props.sendOneTimeReportRequest} 
								authUser={props.authUser}
								userProperties={props.userProperties}
								dateOfData={UserTimezoneFormat(
									props.userProperties,
									dashboardValues.dateOfData,
								)}
							/>
						</div>
						<div ref={printRef}>
							<div>
								<DashboardContainer />
							</div>
						</div>
					</div>
				</div>

			);
		}

		if (!dashboardRole) {
			return (
				<div>
					<div className={classes.lastLoginMsg}>{`You've been logged in since ${UserTimezoneFormat(
						props.userProperties,
						currentUser.lastLogin,
					)}`}</div>
					<h1>{getGreeting()}</h1>
					<p style={{ fontSize: '18px' }}>
						Experience streamlined management of your RightNow Inventory Devices with RightNow Track. Access inventory
						levels, transaction logs, temperature data, scheduled reports, and more in a user-friendly interface.
					</p>
					{getCabinetLinks()}
					<p>
						For customer support, please call (855)-544-2122 or email{' '}
						<a href="mailto:customersupport@rightnowinventory.com">customersupport@rightnowinventory.com</a>.
					</p>

				</div>
			)
		}

		return <></>
	}

	const getCabinetLinks = () => {
		if (currentUser.claims['ALL']) {
			return (
				<div style={{ margin: '40px 0' }}>
					<h2>RNI Devices</h2>
					<CabinetsContainer />
				</div>
			);
		}
		const cabinets = props.cabinets || [];
		if (cabinets.length > 0) {
			return (
				<div style={{ margin: '20px 0' }}>
					<h2>RNI Devices</h2>
					<Grid container spacing={3}>
						{cabinets.map((cabinet: any) => (
							<Grid item xs={3}>
								<Link className={navStyles.link} to={`/cabinet-details/${cabinet.customerId}/${cabinet.cabinetId}`}>
									<Paper className={classes.paper}>
										<Grid container alignItems="center">
											<Grid item xs>
												{/* Placeholder for cabinet icon when available */}
												{/* <div style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
													<CloudIcon />
												</div> */}
												<b>{valueMapper(cabinet.cabinetType, 'cabinetTypes')}</b>
											</Grid>
											<Grid item xs={9}>
												Device Name: {cabinet.cabinetName || 'Unknown'}
												<br />
												Device Id: {cabinet.cabinetId}
												<br />
												Status: {valueMapper(cabinet.cabinetProperties.connectivityStatus, 'connectivityStatus')}
											</Grid>
										</Grid>
									</Paper>
								</Link>
							</Grid>
						))}
					</Grid>
				</div>
			);
		}
	};

	return (
		<div>
			<BasePage title={'Dashboard'}>
				<div className={classes.appBarSpacer}></div>
				<Container maxWidth={false} className={classes.container}>
					{getDashboardToDisplay()}
				</Container>
			</BasePage>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	dashboardValues: state.dashboard.dashboardValues ? state.dashboard.dashboardValues : [],
	cabinets: state.user.userCabinets ? state.user.userCabinets.result : [],
	authUser: state.user.authUser,
	userProperties:
		state.user &&
			state.user.authUser &&
			state.user.authUser.record &&
			state.user.authUser.record.properties &&
			state.user.authUser.record.properties instanceof Array
			? state.user.authUser.record.properties
			: [],
});

const mapDispatchToProps = (dispatch: any) => ({
	sendOneTimeReportRequest: (params: any) => dispatch(UserActions.sendOneTimeReport(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);
