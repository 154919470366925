import { call, put, select } from 'redux-saga/effects';
import {firebase} from '../../auth/firebase';
import {
	UserActions,
	TenantsActions,
	UserManagementActions,
	UIAction,
	ProductActions,
	AlertActions,
	CabinetActions,
	OrderActions,
	DashboardActions,
	AuditActions,
} from '../actions/index';
import {
	UserService,
	TenantsService,
	ProductService,
	AlertsService,
	CabinetService,
	OrdersService,
	DashboardService,
	AuditService,
} from '../services';
import AuthUser from '../lib/authUser';
import { Constants } from '../../constants/Constants';
import { themeNames } from '../../hooks/styles/muiTheme';
import { getAuth, signInWithCustomToken } from "firebase/auth";

export function* handleRniAuthToken(action: ReturnType<typeof UserActions.handleRniAuthToken>): any {
	try {
		const auth = getAuth(firebase);

		yield auth.signOut();
		yield put(UserActions.clearUserState());
		const userCredential = yield signInWithCustomToken(auth, action.payload.token);
		const idToken = yield userCredential.user.getIdToken();
		const uid = userCredential.user.uid;
		localStorage.setItem('uid', uid);
		localStorage.setItem('id_token', idToken);
		yield put(UserActions.userTokenRequestCompleteAction({ token: idToken, uid: uid }));
		yield put(UserActions.userRecordRequestStartAction());
	} catch (err) {
		console.error('saga error', err);
	}
}

export function* handleRniAuthPageRefresh(action: ReturnType<typeof UserActions.handleRniAuthPageRefresh>): any {
	try {
		const uid = localStorage.getItem('uid');
		const idToken = localStorage.getItem('id_token');
		if (!uid || !idToken) {
			console.error("NO ID TOKEN")
			throw new Error('User is not authenticated.');
		}
		yield put(UserActions.userTokenRequestCompleteAction({ token: idToken, uid: uid }));
		yield put(UserActions.userRecordRequestStartAction());
	} catch (err) {
		console.error('saga error', err);
		window.location.href = '/logout?authError=true';
	}
}

export function* setupUser(action: ReturnType<typeof UserActions.sendUserSetup>) {
	try {
		yield put(UIAction.showLoader(true));
		yield put(UserManagementActions.userErrorAction(false));
		yield call(UserService.getInstance().setupUser, action.payload);
		window.location.href = '/';
	} catch (err: any) {
		const errorBody = err.response.data;
		yield put(UserManagementActions.userErrorAction(errorBody));
		yield put(UIAction.showLoader(false));
		console.error('User setup saga error:', errorBody);
	}
}

/*
 * encompasses service calls needed for general setup of the app
 * when a user logs in
 * called after success of getFirebaseToken above
 */
export function* getUserRecord(action: ReturnType<typeof UserActions.userRecordRequestStartAction>): any {
	const uid = yield select(state => state.user.uid);
	const location = yield select(state => state.router.location);
	try {
		//use userId to look up full user record
		const userRecord = yield call(UserService.getInstance().getUserRecord, uid);
		const roles = userRecord && userRecord.claims ? userRecord.claims[Object.keys(userRecord.claims)[0]] : null;

		const { data } = yield call(TenantsService.getInstance().getUserTenants, location);

		// Get dashboard values if role defined for user
		const dashboardRoles: { roleName: string, dashboardType: 'OPERATIONS' | 'SERVICES' | 'CUSTOMER' }[] = [
			{ roleName: 'dashboard_operations', dashboardType: 'OPERATIONS' },
			{ roleName: 'dashboard_services', dashboardType: 'SERVICES' },
			{ roleName: 'dashboard_customer', dashboardType: 'CUSTOMER' },
		];
		const dashboardRole = dashboardRoles.find(el => Object.keys(roles).includes(el.roleName));

		if (dashboardRole) {
			try {
				const dashboardValues = yield call(DashboardService.getInstance().getDashboardValues, dashboardRole.dashboardType, []);
				yield put(DashboardActions.setAllDashboardValues(dashboardValues.data));

			} catch (err) {
				console.error('error getting dashboard values', err);
				yield put(DashboardActions.setAllDashboardValues([]));
			}
		}

		//get list of all products
		try {
			const allProducts = yield call(ProductService.getInstance().getAllProductsList);
			yield put(ProductActions.setAllProductsList(allProducts.data));
		} catch (err) {
			console.error('error getting all products list', err);
			yield put(ProductActions.setAllProductsList([]));
		}

		//get list of all product groups
		try {
			const payload = { source: 'fff' };
			const { data } = yield call(ProductService.getInstance().getProductGroups, payload);
			yield put(ProductActions.setProductGroups(data));
		} catch (err) {
			console.error('error getting product groups', err);
			yield put(ProductActions.setProductGroups([]));
		}

		//preload cabinets to appear on dashboard if able
		try {
			if (roles && roles.cabinets) {
				if (userRecord.claims['ALL']) {
					const cabinetResponse = yield call(CabinetService.getInstance().getCabinetsWithProperties, {
						offset: 0,
						limit: 10,
						order: 'cabinetId ASC',
					});
					yield put(CabinetActions.cabinetsTableRequestCompletedAction(cabinetResponse.data));
				} else {
					const cabinetResponse = yield call(CabinetService.getInstance().getCabinetsWithProperties, { limit: 999 });
					yield put(UserActions.setUserCabinets(cabinetResponse.data));
				}
			} else {
				throw new Error('Insufficient role. Cannot preload cabinets.');
			}
		} catch (err) {
			console.error('error getting cabinet list', err);
			yield put(UserActions.setPersonas([]));
		}

		//preload restock recommendation data
		try {
			if (roles && roles.orders) {
				if (userRecord.claims['ALL']) {
					const payload = {"offset":0,"orderBy":["customerId ASC"],"enforceRestockDowExclusions":true,"inventoryStatuses":["Urgent","Replen","Opportunity"]}
					const orderReqPayload = [{key: 'filter', value: JSON.stringify({"offset":0,"limit":10,"where":{"and":[{"status":"PENDING"}]},"order":["created_at DESC"]})}]
					let recResults: any = null;
					let excResults: any = null;
					let orderReqResults: any = null;
					const recResp = yield call(OrdersService.getInstance().getRecommendations, payload);
					recResults = recResp.data;
					yield put(OrderActions.setRecommendationsTotal(recResults.totalRecords));
					const expResp = yield call(OrdersService.getInstance().getInventoryExceptions, payload);
					excResults = expResp.data;
					yield put(OrderActions.setInventoryExceptionsTotal(excResults.totalRecords));
					const orderReqResp = yield call(OrdersService.getInstance().getOrderRequests, orderReqPayload);
					orderReqResults = orderReqResp.data;
					yield put(OrderActions.setOrderRequests(orderReqResults));
					const exclusionsParams: any[] = []
					const exclusionsResponse: {data: any[]} = yield call(OrdersService.getInstance().getOrderExclusions, exclusionsParams);
					yield put(OrderActions.setOrderExclusions(exclusionsResponse.data || []));
				}
			}
		} catch (err) {
			console.error('error getting restock recommendation data', err);
			yield put(OrderActions.setRecommendationsTotal(null));
			yield put(OrderActions.setInventoryExceptionsTotal(null));
			yield put(OrderActions.setOrderExclusions(null));
		}

		//get definitions of all possible personas any user may have via listPersonas
		try {
			if (roles && roles.personas) {
				const personas = yield call(UserService.getInstance().getPersonas);
				yield put(UserActions.setPersonas(personas));
			} else {
				throw new Error('Insufficient role. Cannot preload personas.');
			}
		} catch (err) {
			console.error('error getting personas list', err);
			yield put(UserActions.setPersonas([]));
		}

		//get list of incident rules
		try {
			if (roles && roles.incidents) {
				const { data } = yield call(AlertsService.getInstance().getIncidentRules, action.payload);
				yield put(AlertActions.setIncidentRules(data));
			} else {
				throw new Error('Insufficient role. Cannot preload incident rules.');
			}
		} catch (err) {
			console.error('error getting incident rules', err);
			yield put(AlertActions.setIncidentRules([]));
		}

		//get Class of Trade options for filter
		let classOfTradeOptions = { data: null };
		classOfTradeOptions = yield call(TenantsService.getInstance().getClassOfTradeOptions);
		yield put(TenantsActions.setClassOfTradeOptions(classOfTradeOptions.data));

		//get Facility Type options for filter
		let facilityTypeOptions = { data: null };
		facilityTypeOptions = yield call(TenantsService.getInstance().getFacilityTypeOptions);
		yield put(TenantsActions.setFacilityTypeOptions(facilityTypeOptions.data));

		//get Event Type options for filter
		// let eventTypeOptions = { data: null };
		const eventTypeOptions = yield call(AuditService.getInstance().getEventTypes);
		yield put(AuditActions.setEventTypes(eventTypeOptions.data));

		yield put(TenantsActions.successTenantsList(data));
		userRecord.tenants = data.result;
		const authStorage = localStorage.getItem('id_token');
		if (!authStorage) {
			throw new Error('User is not authenticated');
		}
		const authUser = new AuthUser(userRecord, authStorage);
		yield put(UserActions.userRecordRequestCompleteAction(authUser));

		const userClaims = yield select(state => state.user.authUser.record.claims);
		const userNav = yield call(UserService.getInstance().getUserNav, userClaims);

		// get and set user report queue
		const updatedQueue = yield call(UserService.getInstance().getUserReportQueue);
		const rq = updatedQueue.data?.reportQueue;
		yield put(UserActions.getUserReportQueueSuccess(rq || []));

		let themePreference = themeNames.LIGHT;

		const savedThemeProp = userRecord.properties.find((p: any) => {
			return p.propertyKey === 'USE_DARK_THEME';
		});

		if (savedThemeProp && savedThemeProp.propertyValue === '1') {
			themePreference = themeNames.DARK;
		}

		yield put(UserActions.setUserNav(userNav));

		//set the user's theme in the Redux state
		yield put(UserActions.setUserTheme(themePreference));

		//also set the preferred theme in localStorage. This way on most page loads, we'll have it sooner
		//and won't have to wait for the user service to return, so users with dark theme chosen won't see a jarring "flash"
		localStorage.setItem('fdiPortal_themePreference', themePreference);
	} catch (err: any) {
		console.error('user saga error', err);
		if (err?.response?.status === 401) {
			window.location.href = '/logout?sessionTimeout=true';
		}
	}
}

export function* createUserProperty(action: ReturnType<typeof UserActions.userPropertiesCreateStartAction>) {
	try {
		yield put(UIAction.showLoader(true));
		yield call(UserService.getInstance().createUserProperty, action.payload);

		if (action.payload.isThemeUpdate) {
			localStorage.setItem('fdiPortal_themePreference', action.payload.newThemeName);
			window.location.reload();
		} else {
			yield put(
				UserActions.getUserPropertiesAction({
					user_id: action.payload.user_id,
				}),
			);
			yield put(UserActions.userRecordRequestStartAction());
		}
	} catch (err: any) {
		const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${errorMessage} in createUserProperty`,
				type: 'error',
			}),
		);
		yield put(UIAction.showLoader(false));
	}
}

export function* getUserProperty(action: ReturnType<typeof UserActions.getUserPropertiesAction>) {
	try {
		const { data } = yield call(UserService.getInstance().getUserProperties, action.payload);
		yield put(UserActions.successUserPropertiesAction(data));
		yield put(UIAction.showLoader(false));
	} catch (err) {
		yield put(UIAction.showLoader(false));
		console.error('saga error', err);
	}
}

export function* updatePersona(action: ReturnType<typeof UserActions.updatePersonaAction>): any {
	yield put(UserActions.personaSaveComplete(null));

	try {
		const newPersona = yield call(UserService.getInstance().updatePersona, action.payload);
		const personasCopy: any = yield select(state => state.user.allAvailablePersonas);
		const personaIndex = personasCopy.findIndex((persona: any) => persona.identifier === newPersona.identifier);

		personasCopy[personaIndex] = newPersona;

		yield put(UserActions.setPersonas(personasCopy));
		yield put(UserActions.personaSaveComplete(true));

		yield put(
			UserActions.setSnackbarMessage({
				message: 'Persona save succeeded.',
				type: 'success',
			}),
		);
	} catch (err: any) {
		console.error('update persona error:', err);
		const errorType = err.message.includes('403') ? '403' : null;
		yield put(UserActions.personaSaveComplete(false, errorType));
	}
}

export function* createPersona(action: ReturnType<typeof UserActions.createPersonaAction>): any {
	try {
		const newPersona = yield call(UserService.getInstance().createPersona, action.payload);
		const personasCopy: any = yield select(state => state.user.allAvailablePersonas);

		personasCopy.push(newPersona);

		yield put(
			UserActions.setSnackbarMessage({
				message: 'Persona creation succeeded.',
				type: 'success',
			}),
		);
		yield put(UserActions.setPersonas(personasCopy));
	} catch (err: any) {
		console.error('create persona error:', err);
		const errorType = err.message.includes('403') ? '403' : null;
		yield put(UserActions.personaSaveComplete(false, errorType));
	}
}

export function* createReportSubscription(action: ReturnType<typeof UserActions.createReportSubscription>): any {
	try {
		yield put(UIAction.showLoader(true));
		const callback = action.payload.callback || null
		delete action.payload.callback
		const newSubscrObj = yield call(UserService.getInstance().createReportSubscription, action.payload);

		if(!action.payload.isThirdParty) yield put(UserActions.addUserSubscriptionSuccess(newSubscrObj));
		if(callback) callback()

		yield put(UIAction.showLoader(false));
		yield put(
			UserActions.setSnackbarMessage({
				message: 'Subscription creation succeeded.',
				type: 'success',
			}),
		);
	} catch (err) {
		yield put(UIAction.showLoader(false));
		yield put(
			UserActions.setSnackbarMessage({
				message: 'An error occurred creating your subscription.',
				type: 'error',
			}),
		);
	}
}

export function* editReportSubscription(action: ReturnType<typeof UserActions.editReportSubscription>): any {
	try {
		yield put(UIAction.showLoader(true));
		const callback = action.payload.callback || null
		const newSubscrObj = yield call(UserService.getInstance().editReportSubscription, action.payload.subscription);

		if(!action.payload.isThirdParty) yield put(UserActions.editUserSubscriptionSuccess(newSubscrObj));
		if(callback) callback()

		yield put(UIAction.showLoader(false));
		yield put(
			UserActions.setSnackbarMessage({
				message: 'Subscription edit succeeded.',
				type: 'success',
			}),
		);
	} catch (err) {
		yield put(UIAction.showLoader(false));
		yield put(
			UserActions.setSnackbarMessage({
				message: 'An error occurred editing your subscription.',
				type: 'error',
			}),
		);
	}
}

export function* updateUserQueueItem(action: ReturnType<typeof UserActions.updateUserQueueItem>): any {
	try {
		const newSubscrObj = yield call(UserService.getInstance().updateUserQueueItem, action.payload);
	} catch (err: any) {
		throw new Error(err);
	}
}

export function* sendOneTimeReportRequest(action: ReturnType<typeof UserActions.sendOneTimeReport>) {
	try {
		yield put(UIAction.showLoader(true));
		yield call(UserService.getInstance().sendOneTimeReportRequest, action.payload);

		yield put(UserActions.sendOneTimeReportSuccess());
		yield put(UIAction.showLoader(false));
		const isEnqueue = action.payload.delivery_method === 'UserQueue';

		yield put(
			UserActions.setSnackbarMessage({
				message: isEnqueue ? 'Your report will be available in your queue soon.' : 'Your report has been sent.',
				type: 'success',
			}),
		);

		//if it was enqueued, add one to the current count of reports we are awaiting
		if (isEnqueue) {
			yield put(UserActions.adjustAwaitedQueueItemsCount(1));
		}
	} catch (err) {
		yield put(UIAction.showLoader(false));
		yield put(
			UserActions.setSnackbarMessage({
				message: 'An error occurred sending your report.',
				type: 'error',
			}),
		);

		console.error(err);
	}
}

export function* deleteReportSubscription(action: ReturnType<typeof UserActions.deleteReportSubscription>): any {
	try {
		yield put(UIAction.showLoader(true));
		const callback = action.payload.callback || null
		delete action.payload.callback
		const deletedSubscrName = yield call(UserService.getInstance().deleteReportSubscription, action.payload); //returns name of deleted on success

		if(!action.payload.isThirdParty) yield put(UserActions.deleteUserSubscriptionSuccess(deletedSubscrName));
		if(callback) callback()


		yield put(UIAction.showLoader(false));
		yield put(
			UserActions.setSnackbarMessage({
				message: 'Subscription deletion succeeded.',
				type: 'success',
			}),
		);
	} catch (err) {
		yield put(UIAction.showLoader(false));
		yield put(
			UserActions.setSnackbarMessage({
				message: 'Subscription deletion failed!',
				type: 'error',
			}),
		);
	}
}

export function* getUserReportQueue(action: ReturnType<typeof UserActions.getUserReportQueue>): any {
	try {
		const numberAwaited = yield select(state => state.user?.awaitingQueueItemCount);

		if (!numberAwaited || numberAwaited < 1) {
			//not awaiting any reports so don't make polling call
			return;
		}

		const oldQueue = yield select(state => state.user.authUser?.record?.reportQueue);

		const updatedQueue = yield call(UserService.getInstance().getUserReportQueue);
		const rq = updatedQueue.data?.reportQueue;

		yield put(UserActions.getUserReportQueueSuccess(rq || []));

		const oldCount = oldQueue ? oldQueue.length : 0;
		const newCount = rq ? rq.length : 0;

		if (newCount > oldCount) {
			//decrement awaiting count by difference
			const reportsAdded = newCount - oldCount;
			yield put(UserActions.adjustAwaitedQueueItemsCount(-reportsAdded));

			yield put(
				UserActions.setSnackbarMessage({
					message: 'A new report is available to download in your queue.',
					type: 'success',
					snackbarAutohide: null,
				}),
			);
		}
	} catch (err) {
		console.error(err);
	}
}
