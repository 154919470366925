import { connect } from 'react-redux';
import { CabinetActions, DashboardActions } from '../../redux/actions';
import DashboardComponent from './dashboard.component';

const mapStateToProps = (state: any) => ({
	dashboardValues: state.dashboard.dashboardValues ? state.dashboard.dashboardValues : [],
	selectedCustomer: state.ui.selectedCustomer,
	cabinets: state.user.userCabinets ? state.user.userCabinets.result : [],
	authUser: state.user.authUser,
	abwRemindersDismissed: state.dashboard.dismissAbwReminders,
	cycleCountRemindersDismissed: state.dashboard.dismissCycleCountReminders,
	properties:
		state.user &&
		state.user.authUser &&
		state.user.authUser.record &&
		state.user.authUser.record.properties &&
		state.user.authUser.record.properties instanceof Array
			? state.user.authUser.record.properties
			: []
});

const mapDispatchToProps = (dispatch: any) => ({
	getDashboardValues: (payload: any) => dispatch(DashboardActions.getAllDashboardValues(payload)),
	setDashboardValues: (dashboardValues: any) => dispatch(DashboardActions.setAllDashboardValues(dashboardValues.data)),
	dismissAbwReminders: (payload: boolean) => dispatch(DashboardActions.dismissAbwReminders(payload)),
	dismissCycleCountReminders: (payload: boolean) => dispatch(DashboardActions.dismissCycleCountReminders(payload)),
	getCabinets: (payload: any) => dispatch(CabinetActions.cabinetsTableRequestStartAction(payload)),
});

export const DashboardContainer = connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);
