import * as React from 'react';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import { IconButton, Tooltip } from '@material-ui/core';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { GetUserTimezone } from '../../hooks/functions';

export const DashboardExport: React.FC<any> = (props: any) => {

  const handleDownloadPdf = async () => {
    const element = props.printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth() - 10;
    const pdfHeight =
      ((imgProperties.height * pdfWidth) / imgProperties.width) - 10;

    pdf.addImage(data, 'PNG', 5, 5, pdfWidth, pdfHeight);
    pdf.save('rni_track_dashboard_'+props.dateOfData);
  };

  const handleDashboardCsvExport = async () => {
    const params = {
        filter_object_from_ui: {},
        report_type: "TrackDashboardExport",
        report_frequency: "OneTime",
        delivery_method: "UserQueue",
        file_type: "csv",
        user_name: props.authUser?.record.email,
        recipient_addresses: JSON.stringify([props.authUser?.record.email]),
        timezone: GetUserTimezone(props.userProperties),
        origin_application: "RNI",
        report_additional_metadata: {}
    }
    props.csvExport(params)
  }

  return (
    <>
      Dashboard Actions: 
      {props.dashboardRole === 'dashboard_customer' && <IconButton aria-label="Export Dashboard Data" onClick={handleDashboardCsvExport}>
        <Tooltip id="dashboard-data-export" title="Export Dashboard Data">
          <CloudDownloadIcon />
        </Tooltip>
      </IconButton>}
      <IconButton aria-label="Export Dashboard as PDF" onClick={handleDownloadPdf}>
        <Tooltip id="print-dashboard-pdf" title="Export Dashboard as PDF">
          <PdfIcon />
        </Tooltip>
      </IconButton>
    </>
  )
}