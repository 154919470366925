import Api from '../lib/api';
import { INVENTORY_MANAGEMENT, API_BASE_URL, ORDERS } from '../constants/api.url';
import { HttpResponse, ResultSet } from '../models/common/result.set';
import { ParInventoryRow } from '../models/inventory-management/inventory-management';
import TimestampNormalizer from '../lib/timestamp-normalizer';

export class InventoryManagementService {
	private static instance: InventoryManagementService;
	private constructor() {}

	public static getInstance(): InventoryManagementService {
		if (!InventoryManagementService.instance) {
			InventoryManagementService.instance = new InventoryManagementService();
		}
		return InventoryManagementService.instance;
	}

	public getParInventory = async (filter: any): Promise<HttpResponse<ResultSet<ParInventoryRow>>> => {
		const params = this.getQueryStringParams(filter);
		let parInventory = await Api.get(INVENTORY_MANAGEMENT.LIST_PAR, params);
		if (!filter.groupBy) {
			const { result } = parInventory.data;
			const timestampNormalizer = new TimestampNormalizer(result);
			const resultTimestampsConverted = timestampNormalizer.convertToLocal('Last_Update');
			parInventory.data = { result: resultTimestampsConverted, total_record: parInventory.data.total_record };
		}
		return parInventory;
	};

	public getRestockRecommendations = async (filter: any): Promise<HttpResponse<ResultSet<any>>> => {
		const response = await Api.post(`${ORDERS.URL}/restockRecommendations`, filter);
		return response
	};

	public getCustomerCabinetCycleCounts = async (params: {customerId: string, cabinetId: string}[]): Promise<HttpResponse<ResultSet<any>>> => {
		let cabinetInventory = await Api.get(`${INVENTORY_MANAGEMENT.LIST_INVENTORY}/cycleCount/${params[0].customerId}/${params[0].cabinetId}`);
		return cabinetInventory;
	};

	public getTenantInventory = async (params: any | null): Promise<HttpResponse<ResultSet<ParInventoryRow>>> => {
		let cabinetInventory = await Api.get(INVENTORY_MANAGEMENT.LIST_INVENTORY, params);
		return cabinetInventory;
	};

	public getCartridgeInventory = async (params: any | null): Promise<HttpResponse<ResultSet<ParInventoryRow>>> => {
		let cabinetInventory = await Api.get(`${INVENTORY_MANAGEMENT.LIST_INVENTORY}/cartridge`, params);
		return cabinetInventory;
	};

	public getSerialInventory = async (params: any | null): Promise<HttpResponse<ResultSet<ParInventoryRow>>> => {
		let cabinetInventory = await Api.get(`${INVENTORY_MANAGEMENT.LIST_INVENTORY}/serial`, params);
		return cabinetInventory;
	};

	public getInventoryCsv = async (filter: any): Promise<any> => {
		let params = filter;
		let url = INVENTORY_MANAGEMENT.LIST_INVENTORY;
		const customerId = filter.find((obj: any) => obj.key === 'customerId')?.value;
		const tabView = filter.find((obj: any) => obj.key === 'tab').value;
		if (tabView === 'cartridge') url += '/cartridge';
		if (tabView === 'serial') url += '/serial';
		const { data } = await Api.get(url, params);
		var blob = new Blob([`\ufeff${data}`]);
		var link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = `${customerId ? customerId + '_' : ''}${tabView}_inventory_report_${new Date().toDateString()}.csv`;
		link.click();
	};

	public patchInventoryUsage = async (payload: any): Promise<any> => {
		return await Api.patch(INVENTORY_MANAGEMENT.POST_USAGE + '?customerId=' + payload.customerId, payload);
	};

	public patchInventoryReceipt = async (payload: any): Promise<any> => {
		return await Api.patch(INVENTORY_MANAGEMENT.PATCH_RECEIPT + '?customerId=' + payload.customerId, payload);
	};

	public updateCabinetProductInventory = async (params: any): Promise<HttpResponse<any>> =>
		await Api.post(`${INVENTORY_MANAGEMENT.URL}/${params.action}`, params.body);

	public reportCabinetInventory = async (payload: any): Promise<HttpResponse<any>> =>
		await Api.post(`${INVENTORY_MANAGEMENT.URL}/onHand`, payload.data);

	public getSuspendedTransactions = async (params: any): Promise<HttpResponse<any>> => {
		const st = await Api.get(INVENTORY_MANAGEMENT.URL + '/suspendedTransactions', params);
		return st;
	};

	public updateSuspendedTransaction = async (id: any, payload: any): Promise<any> => {
		return await Api.patch(INVENTORY_MANAGEMENT.URL + '/suspendedTransactions/' + id, payload);
	};

	public getTags = async (params: any): Promise<any> => {
		const serials = await Api.get(INVENTORY_MANAGEMENT.PRODUCT_TAG_SERIALS, params);
		return serials;
	};

	public getTagLifecycle = async (id: string, additionalLogs: any): Promise<any> => {
		let additionalLogsQuery = '';
		if (additionalLogs.import) additionalLogsQuery = additionalLogsQuery + 'includeImports=1&';
		if (additionalLogs.audit) additionalLogsQuery = additionalLogsQuery + 'includeAudit=1';
		if (additionalLogsQuery) additionalLogsQuery = '?' + additionalLogsQuery;
		return await Api.get(INVENTORY_MANAGEMENT.PRODUCT_TAG_SERIALS + '/productLifeCycle/' + id + additionalLogsQuery);
	};

	public updateProductTagSerials = async (id: string, payload: any): Promise<any> => {
		return await Api.patch(INVENTORY_MANAGEMENT.PRODUCT_TAG_SERIALS + '/' + id, payload);
	};

	public forceVend = async (id: string): Promise<any> => {
		return await Api.post(INVENTORY_MANAGEMENT.PRODUCT_TAG_SERIALS + '/forceVend/' + id, {});
	};

	public revertTag = async (id: string): Promise<any> => {
		return await Api.post(INVENTORY_MANAGEMENT.PRODUCT_TAG_SERIALS + '/revert/' + id, {});
	};

	public returnProductTagSerialsRecord = async (id: string, payload: {rgaNumber: string, comment?: string}): Promise<any> => {
		return await Api.post(INVENTORY_MANAGEMENT.PRODUCT_TAG_SERIALS + '/return/' + id, payload);
	};

	public setProductOwnerForTag = async (id: string, owner: string): Promise<any> => {
		return await Api.put(INVENTORY_MANAGEMENT.URL + '/setProductOwnerForTag/' + id + '/' + owner, {});
	};

	public bulkUpdateProductTagSerials = async (payload: any): Promise<any> => {
		return await Api.patch(INVENTORY_MANAGEMENT.PRODUCT_TAG_SERIALS, payload);
	};

	public bulkForceVend = async (payload: any): Promise<any> => {
		return await Api.post(INVENTORY_MANAGEMENT.PRODUCT_TAG_SERIALS + '/forceVend', payload);
	};

	public bulkRevertTag = async (payload: any): Promise<any> => {
		return await Api.post(INVENTORY_MANAGEMENT.PRODUCT_TAG_SERIALS + '/revert', payload);
	};

	public bulkReturnProductTagSerialsRecord = async (payload: any): Promise<any> => {
		return await Api.post(INVENTORY_MANAGEMENT.PRODUCT_TAG_SERIALS + '/return', payload);
	};

	public refreshPar = async (customerId: any): Promise<any> => {
		return await Api.post(INVENTORY_MANAGEMENT.LIST_PAR + '/refresh', { customerId: customerId});
	};

	private getQueryStringParams(filter: any) {
		let params = null;
		if (filter) {
			params = Object.keys(filter).map(filterKey => ({
				key: filterKey,
				value: filter[filterKey],
			}));
		}
		return params;
	}
}
