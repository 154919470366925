export const valueMapper = (value: any, map: string) => {
	return maps[map][value];
};

const maps: any = {
	cabinetTypes: {
		EM: 'RightNow Ready',
		RFID: 'RightNow Verified',
		Virtual: 'RightNow Cloud',
		WEIGHT: 'RightNow Flex',
		VISION: 'RightNow Secure',
	},
	cabinetStates: {
		'10': 'Manufacture',
		'20': 'Stock',
		'21': 'Configure',
		'22': 'SetUp',
		'30': 'Active',
		'31': 'Active Lock',
		'40': 'In Transit',
		'50': 'Backup',
		'60': 'Repair',
		'70': 'Retired',
	},
	connectivityStatus: {
		'2': 'OFFLINE (<15min)',
		'3': 'OFFLINE',
		'4': 'ONLINE'
	}
};
