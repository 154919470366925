import * as React from 'react';
import { FormControl, IconButton, InputLabel, Menu, MenuItem, Select } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

export const ButtonFilter = (props: any) => {
  const [selected, setSelected] = React.useState<number|null>(null)
  return <div style={{margin: '0 0 10px 0'}}>
    {props.filter.options.map((option: any, index: number) => {
      return (<div 
        style={{
          display: 'inline',
          padding: '6px',
          cursor: 'pointer',
          backgroundColor: index === selected ? 'grey' : 'lightgrey',
          color: index === selected ? 'white' : 'black',
          fontSize: 10,
          border: index === selected ? '1px solid black' : 'none'
        }}
        onClick={() => {
          setSelected(index === selected ? null : index)
          props.applyFilter(props.filter, index === selected ? null : option.value)
        }}
      >
        {option.key}
      </div>)
    })}
  </div>
}

export const SelectMenuFilter = (props: any) => {
  const [selected, setSelected] = React.useState<string>('all')
  return (
    <FormControl variant="outlined">
      <Select
        value={selected}
        onChange={(event: any) => {
          setSelected(event.target.value)
          let value = event.target.value
          if (event.target.value === 'all') value = null
          props.applyFilter(props.filter, value)
        }}
      >
        {props.filter.options.map((option: any, index: number) => {
          return (
            <MenuItem key={'dashboardSelectMenuOption_' + index} value={option.value}>{option.key}</MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export const CornerMenuFilter = (props: any) => {
	const [menuOpen, setMenuOpen] = React.useState(false);
	const [menuAnchor, setMenuAnchor] = React.useState<any>(null);
	return (
		<div style={{position: 'absolute', top: 10, right: 10}}>
			<IconButton
				style={{ padding: 0 }}
				aria-label="more"
				aria-controls="long-menu"
				aria-haspopup="true"
				onClick={e => {
					setMenuOpen(!menuOpen);
					setMenuAnchor(e.currentTarget);
				}}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id="long-menu"
				anchorEl={menuAnchor}
				keepMounted
				open={menuOpen}
				onClose={() => {
					setMenuOpen(false);
					setMenuAnchor(null);
				}}
				PaperProps={{
					style: {
						height: 'auto',
						width: '20ch',
					},
				}}
				MenuListProps={{ id: 'dashboard-widget-' +  props.cardName}}
			>
				{props.filter.options.map((option: any) => (
					<MenuItem
						key={'dashboard-widget-' +  props.cardName + '_option_' + option.key}
						onClick={() => {
							props.applyFilter(props.filter, option.value)
							setMenuOpen(false);
						}}
					>
						{option.key}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
};