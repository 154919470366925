import * as React from 'react';
import { Link } from 'react-router-dom';
import { CommonStyles } from '../../hooks/styles';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';


export const DashboardReminders: React.FC<any> = (props: any) => {
  const classes = CommonStyles();
  const [open, setOpen] = React.useState<boolean[]>(props.reminders.map(() => true))

  const authCheck = (reminder: any) => {
    const reminderResourceMap: any = {
      abw: 'invoices',
      cycleCount: 'inventory'
    }
    return props.authUser?.authorizationCheck({
      action: 'create',
      resource: reminderResourceMap[reminder.reminderType],
      tenantId: props.customerId,
    })
  }

  const renderReminder = (reminder: any, index: number) => {

    if (!authCheck(reminder)) {
      return null
    }
    
    let reminderMsg: any = null
    
    if (reminder.reminderType === 'abw' && !props.dismissals.abw) {
      reminderMsg = <>
        Please review <b>{reminder.reminderCount}</b> ABW selection(s) for upcoming invoices.&nbsp;&nbsp;  
        <Link onClick={props.dismissals.dismissAbw} to={`/abw-selection/${props.customerId}/true`}>Go To ABW Selections</Link>
      </>
    }
    if (reminder.reminderType === 'cycleCount' && !props.dismissals.cycleCount) {
      reminderMsg = <>
        Device ID: {reminder.cabinetId} has <b>{reminder.reminderCount}</b> product(s) requiring a cycle count.&nbsp;&nbsp; 
        <Link onClick={props.dismissals.dismissCycleCount} to={`/cabinet-details/${props.customerId}/${reminder.cabinetId}`}>Go To Device</Link>
      </>
    }
    return reminderMsg && <div key={`dashboard-reminder-${index}`}>
      <Collapse in={open[index]}>
        <Alert 
          severity="warning"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                let openCopy = [...open]
                openCopy[index] = false;
                setOpen(openCopy);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {reminderMsg}
        </Alert>
      </Collapse>
    </div>
  }
  return (
    <div style={{ margin: '15px 0 5px 5px'}}>
      {props.reminders.map((reminder: any, index: number) => {
        return renderReminder(reminder, index)
      })}
    </div>
  )
}