import React from "react";
import { DashboardStyles, getModalClasses, getModalStyle, globalColors } from "../../../hooks/styles";
import CancelIcon from '@material-ui/icons/Cancel';
import { Grid } from "@material-ui/core";
import { connect } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import { UserTimezoneFormat, valueMapper } from "../../../hooks/functions";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { getExpiringInventorySeries } from "./chart-data.utils";
import Loading from "../../../components/loading";


const DashboardStackedBarChartRendering: React.FC<any> = props => {

    const modalClasses = getModalClasses();
    const [records, setRecords] = React.useState(props.records);
    const [modalStyling] = React.useState(getModalStyle());
    const dashboardStyling = DashboardStyles();
    const isModal = props.settings?.modalPopUp || false;
    const [chartHeight, setChartHeight] = React.useState(0)
    const [okToRender, setOkToRender] = React.useState(false)

    React.useEffect(() => {
      setTimeout(() => {
        setOkToRender(true)
      }, 1500)
    }, [])

    React.useEffect(() => {
		const viewportHeight = window.innerHeight;
		const percentageHeight = 50; // Adjust this value as needed
		const calculatedHeight = (percentageHeight / 100) * viewportHeight;
		setChartHeight(calculatedHeight);
	}, []);

    const styleToUse = isModal ? modalStyling : dashboardStyling;
    
    const handleClose = () => {
        props.closeChart();
    };

    const options = {
      title: {
          text: props.settings.displayName
      },
      chart: {
          type: "column",
          height: chartHeight
      },
      xAxis: {
          categories: props.settings?.chartCategories,
          title: {
            text: props.settings?.xAxisLabel
        }
      },

      yAxis: {
          stackLabels: {
              enabled: true
          },
          allowDecimals: false,
          min: 0,
          title: {
              text: props.settings?.yAxisLabel
          }
      },
      plotOptions: {
          column: {
              animation: props?.animation ?? true,
              stacking: 'normal'
          }
      },
      legend: {
        align: 'center',
        x: 0,
        verticalAlign: 'top',
        y: 30,
        floating: true,
        backgroundColor: 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
      },
      series: getExpiringInventorySeries(records)
  };

    return (
      <>
        {!okToRender && <Loading message="Loading Widget" />}
        {okToRender && records.length === 0 && <div style={{fontSize: 18}}>{props.settings.displayName}<br/>No data available.</div>}
        {okToRender && records.length > 0 && <div style={{ ...styleToUse, maxWidth: 1200 }} className={isModal === true ? modalClasses.paper : dashboardStyling.cardItem}>
          {props.settings?.modalPopUp && <div className={modalClasses.closeBtn} onClick={handleClose}>
              <CancelIcon color="disabled"></CancelIcon>
          </div>}
          <div>
            <HighchartsReact highcharts={Highcharts} options={options} />
          </div>
        </div>}
      </>
    )
}

const mapStateToProps = (state: any) => ({
});

const mapDispatchToProps = (dispatch: any) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardStackedBarChartRendering);