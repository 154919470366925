import * as React from 'react';
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Chip,
	Modal,
	Paper,
	FormControl,
	TextField,
	Button,
	Grid,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AuthUser from '../../../redux/lib/authUser';
import RestockOrderDetailTable from './restock-order-detail-table';
import tableConfig from './restock-order-detail-tables-config';
import { RestockOrderDetailsStyles } from './restock-order-details.styles';
import moment from 'moment';
import { CommonStyles, getModalClasses, getModalStyle, getOrderDetailModalStyle } from '../../../hooks/styles';
import { SelectBox } from '../../../components/selectBox';
import Loading from '../../../components/loading';
import { UserDateFormat, UserTimezoneFormat } from '../../../hooks/functions';
import CancelIcon from '@material-ui/icons/Cancel';
import { useMediaQuery } from 'react-responsive';

interface RestockOrderDetailProps {
	authUser?: AuthUser;
	close: () => void;
	handleFetchOrders: () => void;
	orderId: any;
	isModal: any;
	restockOrderDetails_igShipmentItems: any;
	restockOrderDetails_warehouseShipment: any;
	restockOrderDetails_restockRequest: any;
	restockOrderDetails_transactions: any;
	restockOrderDetails_sapOrders: any;
	getRestockOrderDetails_igShipmentItems: any;
	getRestockOrderDetails_warehouseShipment: any;
	getRestockOrderDetails_restockRequest: any;
	getRestockOrderDetails_transactions: any;
	getRestockOrderDetails_sapOrders: any;
	updateRestockOrderStatus: any;
	properties: any;
}

export const RestockOrderDetailComponent: React.FC<RestockOrderDetailProps> = props => {
	const modalClasses = getModalClasses();
	const commonStyles = CommonStyles();
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});

	// const { urlOrderId } = useParams<any>();
	const [expanded, setExpanded] = React.useState<string | false>(false);
	const [modalOpen, setModalOpen] = React.useState(false);

	//keep data for each table separate to avoid unnecessary updates of other tables
	//this is the data in form consumed by tables, ie arrays
	//elsewhere we store it in more standard object form for clarity and in case it's needed elsewhere
	//when the service returns JSON, useEffect transforms it to the tabular format
	const [restockRequestTableData, setRestockRequestTableData] = React.useState<any[]>([]);
	const [warehouseShipmentTableData, setWarehouseShipmentTableData] = React.useState<any[]>([]);
	const [igShipmentItemsTableData, setIgShipmentItemsTableData] = React.useState<any[]>([]);
	const [transactionsTableData, setTransactionsTableData] = React.useState<any[]>([]);
	const [sapOrdersTableData, setSapOrdersTableData] = React.useState<any[]>([]);
	const [sapOrderLotsTableData, setSapOrderLotsTableData] = React.useState<any[]>([]);

	const [restockRequestDataAsJson, setRestockRequestDataAsJson] = React.useState<object[]>([]);
	const [warehouseShipmentDataAsJson, setWarehouseShipmentDataAsJson] = React.useState<object[]>([]);
	const [igShipmentItemsDataAsJson, setIgShipmentItemsDataAsJson] = React.useState<object[]>([]);
	const [transactionsDataAsJson, setTransactionsDataAsJson] = React.useState<object[]>([]);
	const [sapOrdersDataAsJson, setSapOrdersDataAsJson] = React.useState<object[]>([]);

	const [orderItemsCount_Requested, setOrderItemsCount_Requested] = React.useState<number>(0);
	const [orderItemsCount_Shipped, setOrderItemsCount_Shipped] = React.useState<number>(0);
	const [orderItemsCount_Tagged, setOrderItemsCount_Tagged] = React.useState<number>(0);
	const [orderItemsCount_Received, setOrderItemsCount_Received] = React.useState<number>(0);

	const [quantity_restockRequest, setQuantity_restockRequest] = React.useState<number>(0);
	const [quantity_warehouseShipment, setQuantity_warehouseShipment] = React.useState<number>(0);
	const [quantity_igShipmentItems, setQuantity_igShipmentItems] = React.useState<number>(0);
	const [quantity_transactions, setQuantity_transactions] = React.useState<number>(0);
	const [quantity_sapOrders, setQuantity_sapOrders] = React.useState<number>(0);
	const [quantity_sapOrderLots, setQuantity_sapOrderLots] = React.useState<number>(0);

	const [orderIdToUpdate, setOrderIdToUpdate] = React.useState<any>(null);
	const [orderToUpdate, setOrderToUpdate] = React.useState<any>(null);
	const [modalOrderStatus, setModalOrderStatus] = React.useState<any>('');
	const [modalStatusMemo, setModalStatusMemo] = React.useState<any>('');
	const [modalOrderStatusError, setModalOrderStatusError] = React.useState<any>(null);
	const [modalLoading, setModalLoading] = React.useState(false);
	const [modalFailureMsg, setModalFailureMsg] = React.useState<any>(null);

	const [overallOrderStatus, setOverallOrderStatus] = React.useState<string>('');

	const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);

	const conf: any = tableConfig.TableConfig;
	const restockOrderClasses = RestockOrderDetailsStyles();

	const handleAccordionChange = (panel: string) => (event: any, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : false);
	};

	/*
	 * make all needed server calls,
	 * store JSON data from server in state,
	 * then useEffect will transform it to arrays for table consumption via transformJsonDataToTableFormat
	 */
	const loadDataForOrderId = (orderId: string) => {
		//SAMPLE ORDER ID IN DEV::: better: 1001768706   //1001790388

		if (orderId) {
			console.log('loading data for five tables for order', orderId);
			props.getRestockOrderDetails_igShipmentItems(orderId);
			props.getRestockOrderDetails_warehouseShipment(orderId);
			props.getRestockOrderDetails_restockRequest(orderId);
			props.getRestockOrderDetails_transactions(orderId);
			props.getRestockOrderDetails_sapOrders(orderId);
			setDataLoaded(true);
		}
	};

	const transformJsonDataToTableFormat = (tableId: string) => {
		const userRecord: any = props.authUser?.record;
		const finalArray: any[] = [];

		switch (tableId) {
			case 'restockRequest':
				// //********* restockRequest from SERVICE /orders/requests/${orderNo}` **********
				restockRequestDataAsJson.forEach((jsonItem: any) => {
					//put into array format and push to finalArray
					finalArray.push([
						jsonItem.customerId,
						jsonItem.cabinetId,
						jsonItem.productId,
						jsonItem.orderQty,
						jsonItem.lot,
						jsonItem.warehouse,
						UserTimezoneFormat(userRecord.properties, jsonItem.createdAt),
						jsonItem.abwCode,
						jsonItem.id,
					]);
				});
				break;

			case 'warehouseShipment':
				//********* warehouseShipment FROM SERVICE /shipments?orderNo=${orderNo} *********

				warehouseShipmentDataAsJson.forEach((jsonItem: any) => {
					finalArray.push([
						jsonItem.customerNumber,
						jsonItem.itemIdentifier,
						jsonItem.vendorItem,
						jsonItem.factorDescription,
						jsonItem.lotNumber,
						parseInt(jsonItem.numPkgsShipped),
						UserDateFormat(props.properties, jsonItem.shipDate),
						jsonItem.orderNumber,
						jsonItem.orderLineNumber,
						jsonItem.trackingNumber,
					]);
				});
				break;
			case 'igShipmentItems':
				igShipmentItemsDataAsJson.forEach((jsonItem: any) => {
					finalArray.push([
						jsonItem.FFFItemId,
						jsonItem.ndc,
						jsonItem.lotNumber,
						formatTagId(jsonItem.tagId),
						jsonItem.shipmentId,
						formatDate(jsonItem.expdate),
						jsonItem.serialNumber || 'UNKNOWN',
					]);
				});
				break;
			case 'transactions':
				//********* transactions FROM SERVICE /orders/${orderNo}/received **********

				transactionsDataAsJson.forEach((jsonItem: any) => {
					finalArray.push([
						jsonItem.customerId,
						jsonItem.cabinetId,
						jsonItem.FFFItemId,
						jsonItem.type,
						jsonItem.lotNumber,
						formatTagId(jsonItem.objectId),
						UserTimezoneFormat(userRecord.properties, jsonItem.sourceTimestamp),
					]);
				});
				break;

			case 'sapOrders':
				// //********* sapOrders from SERVICE /orders/sapOrders/${orderNo}` **********
				sapOrdersDataAsJson.forEach((jsonItem: any) => {
					finalArray.push([
						jsonItem.orderNumber,
						jsonItem.orderLine,
						jsonItem.poNumber,
						jsonItem.orderStatus,
						jsonItem.productId,
						jsonItem.orderQty,
						jsonItem.notes,
						UserTimezoneFormat(userRecord.properties, jsonItem.createdAt),
						UserTimezoneFormat(userRecord.properties, jsonItem.updatedAt),
					]);
				});
				break;
			case 'sapOrderLots':
				// //********* sapOrders from SERVICE /orders/sapOrders/${orderNo}` **********
				sapOrdersDataAsJson.forEach((jsonItem: any) => {
					if (jsonItem.sapOrderLots.length > 0) {
						for (const lot of jsonItem.sapOrderLots) {
							//put into array format and push to finalArray
							finalArray.push([
								lot.orderNumber,
								lot.orderLine,
								lot.productId,
								lot.lot,
								lot.shippedQty,
								lot.receivedQty,
								lot.trackingNumber,
								UserTimezoneFormat(userRecord.properties, lot.createdAt),
								UserTimezoneFormat(userRecord.properties, lot.updatedAt),
							]);
						}
					}
				});
				break;
		}

		return finalArray;

		function getDateFormat() {
			const defaultFormat = 'YYYY-MM-DD';
			let userDateFormat = null;
			if (props.authUser && props.authUser.record) {
				const userRecord: any = props.authUser.record;
				const userDateProperty = userRecord.properties.find((property: any) => property.propertyKey === 'DATEFORMAT');
				if (userDateProperty) {
					userDateFormat = userDateProperty.propertyValue === 'LOCAL' ? defaultFormat : userDateProperty.propertyValue;
				}
			}

			return userDateFormat || defaultFormat;
		}

		function getTimezone() {
			let userTimezone = null;
			if (props.authUser && props.authUser.record) {
				const userRecord: any = props.authUser.record;
				const userTZProperty = userRecord.properties.find((property: any) => property.propertyKey === 'TIMEZONE');
				if (userTZProperty) {
					userTimezone = userTZProperty.propertyValue;
				}
			}

			return userTimezone;
		}

		function formatDate(dateTime: string) {
			return moment
				.utc(dateTime)
				.tz(getTimezone() || moment.tz.guess())
				.format(getDateFormat());
		}

		function formatTagId(tagId: string) {
			try {
				return tagId.slice(-6);
			} catch (e) {
				return 'Invalid ID';
			}
		}
	};

	const getTableData = (tableId: string) => {
		switch (tableId) {
			case 'restockRequest':
				return restockRequestTableData;
			case 'warehouseShipment':
				return warehouseShipmentTableData;
			case 'igShipmentItems':
				return igShipmentItemsTableData;
			case 'transactions':
				return transactionsTableData;
			case 'sapOrders':
				return sapOrdersTableData;
			case 'sapOrderLots':
				return sapOrderLotsTableData;
			default:
				return [];
		}
	};

	const computeQuantityLabel = (tableId: string) => {
		let count = 0;

		switch (tableId) {
			case 'restockRequest':
				restockRequestDataAsJson.forEach((item: any) => {
					count += item.orderQty;
				});

				setQuantity_restockRequest(count);
				break;
			case 'warehouseShipment':
				warehouseShipmentDataAsJson.forEach((item: any) => {
					count += parseInt(item.numPkgsShipped);
				});

				setQuantity_warehouseShipment(count);
				break;
			case 'igShipmentItems':
				count = igShipmentItemsDataAsJson.length;
				setQuantity_igShipmentItems(count);
				break;
			case 'transactions':
				count = transactionsDataAsJson.length;
				setQuantity_transactions(count);
				break;
			case 'sapOrders':
				sapOrdersDataAsJson.forEach((item: any) => {
					count += item.orderQty;
				});

				setQuantity_sapOrders(count);
				break;
			case 'sapOrderLots':
				sapOrdersDataAsJson.forEach((item: any) => {
					if (item.sapOrderLots.length > 0) {
						for (const lot of item.sapOrderLots) {
							count += lot.shippedQty;
						}
					}
				});

				setQuantity_sapOrderLots(count);
				break;
		}
	};

	const computeQuantityLabelClasses = (tableId: string) => {
		let classes = `${restockOrderClasses.statusBreakdownNumberWrapper} `;

		switch (tableId) {
			case 'restockRequest':
				break;
			case 'warehouseShipment':
				if (quantity_warehouseShipment !== quantity_sapOrders) {
					classes += `${restockOrderClasses.statusWarning}`;
				}
				break;
			case 'igShipmentItems':
				if (quantity_igShipmentItems !== quantity_sapOrders) {
					classes += `${restockOrderClasses.statusWarning}`;
				}
				break;
			case 'transactions':
				if (quantity_transactions !== quantity_sapOrders) {
					classes += `${restockOrderClasses.statusWarning}`;
				}
				break;
			case 'sapOrders':
				break;
			case 'sapOrderLots':
				if (quantity_sapOrderLots !== quantity_sapOrders) {
					classes += `${restockOrderClasses.statusWarning}`;
				}
				break;
		}

		return classes;
	};

	const computeOverallOrderStatus = () => {
		let status;
		if (!sapOrdersDataAsJson.length) {
			const firstItem = restockRequestDataAsJson[0] as any;
			status = firstItem['poStatus'];

			for (let i = 1; i < restockRequestDataAsJson.length; i++) {
				const thisItem = restockRequestDataAsJson[i] as any;

				if (thisItem.poStatus !== status) {
					status = 'Partial';
					break;
				}
			}
		} else {
			if (sapOrdersDataAsJson.length === 1) {
				const sapOrder = sapOrdersDataAsJson[0] as any;
				status = sapOrder.orderStatus; // Only 1 record / status
			} else {
				const orderStatuses = sapOrdersDataAsJson.map((el: any) => el.orderStatus);

				// Deduplicate statuses
				const uniqueStatuses = orderStatuses.filter((c, index) => {
					return orderStatuses.indexOf(c) === index;
				});

				if (uniqueStatuses.length === 1) {
					status = uniqueStatuses[0]; // Status the same for all orders
				} else {
					status = 'Partial';
				}
			}
		}

		setOverallOrderStatus(status);
	};

	const computeOverallOrderStatusClasses = () => {
		let classes = `${restockOrderClasses.overallOrderStatusWrapper} `;

		if (overallOrderStatus && overallOrderStatus.toLowerCase() !== 'completed') {
			classes += `${restockOrderClasses.statusWarning}`;
		}

		return classes;
	};

	const getQuantityLabel = (tableKey: string) => {
		switch (tableKey) {
			case 'restockRequest':
				return quantity_restockRequest;
			case 'warehouseShipment':
				return quantity_warehouseShipment;
				break;
			case 'igShipmentItems':
				return quantity_igShipmentItems;
				break;
			case 'transactions':
				return quantity_transactions;
				break;
			case 'sapOrders':
				return quantity_sapOrders;
				break;
			case 'sapOrderLots':
				return quantity_sapOrderLots;
				break;
		}
	};

	// TODO: SET update to sap_orders
	const getTableColumnsWithActions = (tableConfig: any) => {
		switch (tableConfig.id) {
			case 'sapOrders':
				tableConfig.columns[9].options.customBodyRenderLite = (dataIndex: number) => (
					<Button
						type="button"
						className={restockOrderClasses.updateStatusButton}
						variant="contained"
						color="primary"
						onClick={() => {
							setOrderToUpdate(props.restockOrderDetails_sapOrders.result[dataIndex]);
							const thisStatus = props.restockOrderDetails_sapOrders.result[dataIndex].orderStatus;
							setModalOrderStatus(
								['on order', 'completed', 'deleted'].includes(thisStatus.toLowerCase()) ? thisStatus : '',
							);
							const notes = props.restockOrderDetails_sapOrders.result[dataIndex].notes
							setModalStatusMemo(notes || '')
							setModalOpen(true);
						}}
					>
						Update Status
					</Button>
				);
				return tableConfig.columns;

			case 'warehouseShipment':
				tableConfig.columns[9].options = {
					customBodyRender: (value: any) => {
						if (!value) {
							return;
						}
						return (
							<a target="_BLANK" href={`https://www.fedex.com/fedextrack/?trknbr=${value}`}>
								Track Shipment
							</a>
						);
					},
				};
				return tableConfig.columns;

			default:
				return tableConfig.columns;
		}
	};

	const submitOrderStatusUpdate = () => {
		if (!modalOrderStatus) {
			setModalOrderStatusError('Please select a status.');
			return;
		}

		const payload = {
			requestBody: {
				orderNumber: orderToUpdate.orderNumber,
				orderLine: orderToUpdate.orderLine,
				orderStatus: modalOrderStatus,
				...(modalStatusMemo && { notes: modalStatusMemo }),
			},
			success: () => {
				setDataLoaded(false);
				loadDataForOrderId(props.orderId);
				props.handleFetchOrders();
				setTimeout(() => {
					setModalOpen(false);
					setModalLoading(false);
				}, 1500);
			},
			error: () => {
				setModalLoading(false);
				setModalFailureMsg('Failed to update order status');
			},
		};

		setModalLoading(true);
		props.updateRestockOrderStatus(payload);
	};

	React.useEffect(() => {
		console.log('****** loading data on page load *****************', props.orderId);
		!dataLoaded && loadDataForOrderId(props.orderId);
	}, []);

	//***when data is received from server, store the JSON for local use
	React.useEffect(() => {
		if (props.restockOrderDetails_igShipmentItems && props.restockOrderDetails_igShipmentItems.result && dataLoaded) {
			setIgShipmentItemsDataAsJson(props.restockOrderDetails_igShipmentItems.result);
		}
	}, [props.restockOrderDetails_igShipmentItems]);

	React.useEffect(() => {
		if (
			props.restockOrderDetails_warehouseShipment &&
			props.restockOrderDetails_warehouseShipment.result &&
			dataLoaded
		) {
			setWarehouseShipmentDataAsJson(props.restockOrderDetails_warehouseShipment.result);
			computeOverallOrderStatus();
		}
	}, [props.restockOrderDetails_warehouseShipment]);

	React.useEffect(() => {
		if (props.restockOrderDetails_restockRequest && props.restockOrderDetails_restockRequest.result && dataLoaded) {
			setRestockRequestDataAsJson(props.restockOrderDetails_restockRequest.result);
		}
	}, [props.restockOrderDetails_restockRequest]);

	React.useEffect(() => {
		if (props.restockOrderDetails_transactions && props.restockOrderDetails_transactions.result && dataLoaded) {
			setTransactionsDataAsJson(props.restockOrderDetails_transactions.result);
		}
	}, [props.restockOrderDetails_transactions]);

	React.useEffect(() => {
		if (props.restockOrderDetails_sapOrders && props.restockOrderDetails_sapOrders.result && dataLoaded) {
			setSapOrdersDataAsJson(props.restockOrderDetails_sapOrders.result);
		}
	}, [props.restockOrderDetails_sapOrders]);

	//**** transform from JSON to arrays for table
	React.useEffect(() => {
		setRestockRequestTableData(transformJsonDataToTableFormat('restockRequest'));
		computeQuantityLabel('restockRequest');
	}, [restockRequestDataAsJson]);

	React.useEffect(() => {
		setWarehouseShipmentTableData(transformJsonDataToTableFormat('warehouseShipment'));
		computeQuantityLabel('warehouseShipment');
	}, [warehouseShipmentDataAsJson]);

	React.useEffect(() => {
		setIgShipmentItemsTableData(transformJsonDataToTableFormat('igShipmentItems'));
		computeQuantityLabel('igShipmentItems');
	}, [igShipmentItemsDataAsJson]);

	React.useEffect(() => {
		setTransactionsTableData(transformJsonDataToTableFormat('transactions'));
		computeQuantityLabel('transactions');
	}, [transactionsDataAsJson]);

	React.useEffect(() => {
		setSapOrdersTableData(transformJsonDataToTableFormat('sapOrders'));
		computeQuantityLabel('sapOrders');
	}, [sapOrdersDataAsJson]);

	React.useEffect(() => {
		setSapOrderLotsTableData(transformJsonDataToTableFormat('sapOrderLots'));
		computeQuantityLabel('sapOrderLots');
	}, [sapOrdersDataAsJson]);

	const allDataIsLoaded = () => {
		if (overallOrderStatus) {
			return true;
		}
		return false;
	};

	return (
		<div
			style={
				props.isModal
					? {
							...getOrderDetailModalStyle(),
							width: isDesktopOrLaptop ? 'auto' : '90%',
							maxHeight: 800,
							overflow: 'scroll',
					  }
					: {}
			}
			className={props.isModal && modalClasses.paper}
		>
			{props.isModal && (
				<div
					style={{
						position: 'absolute',
						top: 5,
						right: 5,
						color: 'rgba(0, 0, 0, 0.26)',
						cursor: 'pointer',
					}}
				>
					<CancelIcon onClick={() => props.close()} />
				</div>
			)}
			<div className={restockOrderClasses.titleWrapper}>
				<h2>Restock Order Detail Report: {props.orderId}</h2>
				{allDataIsLoaded() && (
					<Chip
						variant="outlined"
						label={overallOrderStatus}
						size="medium"
						className={computeOverallOrderStatusClasses()}
					/>
				)}
			</div>
			{allDataIsLoaded() ? (
				<>
					<div>
						{Object.keys(conf).map(tableKey => {
							const thisData = conf[tableKey];

							return (
								<Accordion expanded={expanded === tableKey} onChange={handleAccordionChange(tableKey)} key={tableKey}>
									<AccordionSummary expandIcon={<ExpandMoreIcon />} id={tableKey}>
										<div className={restockOrderClasses.summaryRow}>
											<h3>{thisData.displayName}</h3>
											<Chip
												variant="outlined"
												label={getQuantityLabel(tableKey)}
												size="medium"
												className={computeQuantityLabelClasses(tableKey)}
											/>
										</div>
									</AccordionSummary>
									<AccordionDetails>
										<div
											style={{
												width: '100%',
												textAlign: 'center',
											}}
										>
											<RestockOrderDetailTable
												contentType={tableKey}
												columns={getTableColumnsWithActions(thisData)}
												tableData={getTableData(tableKey)}
											/>
										</div>
									</AccordionDetails>
								</Accordion>
							);
						})}
					</div>
				</>
			) : (
				<Loading message="" />
			)}
			<Modal
				disableBackdropClick={true}
				open={modalOpen}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<div style={{ ...getModalStyle(), padding: '20px', width: 'auto' }} className={modalClasses.paper}>
					<Paper variant="outlined" style={{ width: '100%', padding: 15 }}>
						<div className={restockOrderClasses.restockRequestStatusModalHeading}>Update Restock Request Status</div>
						<FormControl required variant="outlined" className={commonStyles.formControl}>
							<SelectBox
								style={{ width: 210 }}
								inputLabel={'Status'}
								emptyItemLabel={'Select Status'}
								listItems={[
									{ label: 'On Order', value: 'On Order' },
									{ label: 'Completed', value: 'Completed' },
									{ label: 'Deleted', value: 'Deleted' },
								]}
								selected={modalOrderStatus}
								error={modalOrderStatusError}
								errorText={modalOrderStatusError}
								onChangeItem={(value: any) => {
									setModalFailureMsg(null);
									setModalOrderStatus(value);
								}}
							/>
						</FormControl>
						<FormControl style={{ width: '100%' }} className={commonStyles.formControl}>
							<TextField
								label="Status Change Memo"
								placeholder="Leave a memo (optional)"
								variant="outlined"
								multiline
								minRows={2}
								maxRows={2}
								value={modalStatusMemo || ''}
								onChange={e => {
									setModalFailureMsg(null);
									setModalStatusMemo(e.target.value);
								}}
							/>
						</FormControl>
						<Grid container>
							<Grid item xs={12}>
								{modalLoading ? (
									<Loading message="" />
								) : (
									<>
										<FormControl>
											<Button
												type="button"
												className={commonStyles.searchButton}
												variant="contained"
												color="primary"
												onClick={() => {
													submitOrderStatusUpdate();
												}}
											>
												Save
											</Button>
										</FormControl>
										<FormControl>
											<Button
												type="button"
												className={restockOrderClasses.cancelBtn}
												variant="contained"
												color="default"
												onClick={() => setModalOpen(false)}
											>
												Cancel
											</Button>
										</FormControl>
									</>
								)}
							</Grid>
						</Grid>
					</Paper>
				</div>
			</Modal>
		</div>
	);
};
