import * as React from 'react';
import { connect } from 'react-redux';
import { TenantsActions, UIAction, OrderActions, ProductActions, UserActions } from '../../redux/actions';
import Loading from '../../components/loading';
import { AppBar, Button, FormControl, Grid, IconButton, Menu, MenuItem, Modal, Tab, Tabs, TextField, TextareaAutosize, Tooltip } from '@material-ui/core';
import { CommonStyles, getModalClasses, getModalStyle } from '../../hooks/styles';
import MUIDataTable from 'mui-datatables';
import dataList from '../../constants/dataList';
import EmptyContent from '../../components/emptyContent';
import { GetUserDatetimeFormat, GetUserTimezone, UserTimezoneFormat } from '../../hooks/functions';
import { useMediaQuery } from 'react-responsive';
import { tablePagination } from '../../hooks/functions/TablePagination';
import GlobalFiltersComponentContainer from '../../components/globalFiltersComponent';
import CancelIcon from '@material-ui/icons/Cancel';
import { Visibility } from '@material-ui/icons';
import RestockOrderDetailContainer from '../reports/restock-order-detail/restock-order-detail.container';
import ShareIcon from '@material-ui/icons/Share';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import moment from 'moment';
import { constructEnqueueParams, findUserSelectedColumns } from '../../helpers/report-queue.helper';
import OrderRequestsComponent from '../order-requests/order-requests.component';

const ActionsMenu: React.FC<any> = props => {
	const [menuOpen, setMenuOpen] = React.useState(false);
	const [menuAnchor, setMenuAnchor] = React.useState<any>(null);
	return (
		<div>
			<IconButton
				style={{ padding: 0 }}
				aria-label="more"
				aria-controls="long-menu"
				aria-haspopup="true"
				onClick={e => {
					setMenuOpen(!menuOpen);
					setMenuAnchor(e.currentTarget);
				}}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id="long-menu"
				anchorEl={menuAnchor}
				keepMounted
				open={menuOpen}
				onClose={() => {
					setMenuOpen(false);
					setMenuAnchor(null);
				}}
				PaperProps={{
					style: {
						height: 'auto',
						width: '20ch',
					},
				}}
				MenuListProps={{ id: 'restock-order-actions' }}
			>
				<MenuItem
					id={'view-restock-order-details'}
					onClick={() => {
						setMenuOpen(false);
						setMenuAnchor(null);
						props.actions.viewDetails()
					}}
				>
					View Details
				</MenuItem>
				<MenuItem
						id={'update-restock-order-notes'}
						onClick={() => {
							setMenuOpen(false);
							setMenuAnchor(null);
							props.actions.orderNotes()
						}}
					>
						Order Notes
					</MenuItem>
			</Menu>
		</div>
	);
};

const OrdersComponent: React.FC<any> = (props: any) => {
	const modalClasses = getModalClasses();
	const classes = CommonStyles();
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});
	const firstRender = React.useRef(true);
	const [groupBy, setGroupBy] = React.useState('' as any);
	const [tableStatePersist, setTableStatePersist] = React.useState(null as any);
	const [orderDetailModalOpen, setOrderDetailModalOpen] = React.useState(false);
	const [orderNotesModalOpen, setOrderNotesModalOpen] = React.useState(false);
	const [orderNotes, setOrderNotes] = React.useState('');
	const [orderNotesLoading, setOrderNotesLoading] = React.useState(false);
	const [orderNotesFailureMsg, setOrderNotesFailureMsg] = React.useState('');
	const [shareModalOpen, setShareModalOpen] = React.useState(false);
	const [orderId, setOrderId] = React.useState(null as any);
	const [selectedOrder, setSelectedOrder] = React.useState(null as any);
	const [showValidationError, setShowValidationError] = React.useState<boolean>(false);
	const [orderRequestModalOpen, setOrderRequestModalOpen] = React.useState(false);

	const renderOrderManagementActions = (dataIndex: number) => {
		return <ActionsMenu dataIndex={dataIndex} actions={{
			viewDetails: () => {
				setOrderDetailModalOpen(true);
				const orderNumber = groupBy
					? props.groupedOrders[dataIndex].orderNo
					: props.orders[dataIndex].orderNumber;
				setOrderId(orderNumber);
			},
			orderNotes: () => {
				setOrderNotesModalOpen(true);
				const order = groupBy
					? props.groupedOrders[dataIndex]
					: props.orders[dataIndex];
				setSelectedOrder(order)
				setOrderNotes(order.notes || '')
			},
		}} />
	}

	const renderIcons: any = (dataIndex: number) => {
		if (props.page === 'orderManagement') {
			return renderOrderManagementActions(dataIndex);
		}
		return (
			<div style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
				<Tooltip title="Share" placement="bottom-start">
					<ShareIcon
						onClick={e => {
							setShareModalOpen(true);
							const orderNumber = groupBy
								? props.groupedOrders[dataIndex].orderNo
								: props.orders[dataIndex].orderNumber;
							setOrderId(orderNumber);
						}}
						style={{ marginRight: 10, cursor: 'pointer' }}
					/>
				</Tooltip>
				<Tooltip title="View Details" placement="bottom-start">
					<Visibility
						onClick={e => {
							setOrderDetailModalOpen(true);
							const orderNumber = groupBy
								? props.groupedOrders[dataIndex].orderNo
								: props.orders[dataIndex].orderNumber;
							setOrderId(orderNumber);
						}}
						style={{ cursor: 'pointer' }}
					/>
				</Tooltip>
			</div>
		);
	};

	const columnData = [
		{
			label: 'Order Number',
			name: 'orderNumber',
			change: false,
			viewColumns: false,
			display: true,
		},
		{
			label: 'Order Line',
			name: 'orderLine',
			change: false,
			viewColumns: true,
			display: true,
		},
		{
			label: 'PO Number',
			name: 'poNumber',
			change: false,
			viewColumns: true,
			display: true,
		},
		{
			label: 'Customer Id',
			name: 'customerId',
			change: false,
			viewColumns: false,
			display: true,
		},
		{
			label: 'Device Id',
			name: 'cabinetId',
			change: false,
			viewColumns: false,
			display: true,
		},
		{
			label: 'Device Type',
			name: 'cabinetType',
			display: true,
			change: false,
			viewColumns: false,
			customBodyRender: (value: any) => {
				return dataList.CabinetTypeDropdownList.find(el => value === el.value)?.label ?? '';
			},
		},
		{
			label: 'Order Type',
			name: 'orderType',
			change: false,
			viewColumns: false,
			display: true,
		},
		{
			label: 'Product Id',
			name: 'productId',
			change: false,
			viewColumns: false,
			display: true,
		},
		{
			label: 'Product Description',
			name: 'productName',
			change: false,
			viewColumns: false,
			sort: false,
			display: true,
		},
		{
			label: 'Order Status',
			name: 'orderStatus',
			change: false,
			viewColumns: false,
			display: true,
		},
		{
			label: 'Qty',
			name: 'orderQty',
			change: false,
			viewColumns: true,
			display: true,
		},
		{
			label: 'Created',
			name: 'createdAt',
			change: true,
			viewColumns: true,
			customBodyRender: (value: any) => {
				return UserTimezoneFormat(props.properties, value);
			},
		},
		{
			label: 'Updated',
			name: 'updatedAt',
			change: true,
			viewColumns: true,
			display: true,
			customBodyRender: (value: any) => {
				return UserTimezoneFormat(props.properties, value);
			},
		},
		{
			name: '',
			lable: '',
			display: true,
			change: false,
			viewColumns: false,
			customBodyRenderLite: (dataIndex: number) => {
				return renderIcons(dataIndex);
			},
		},
	];

	const getGroupedOrdersColumnData = () => {
		let groupColumns: any[] = [
			{
				label: 'Order Number',
				name: 'orderNo',
				options: {
					display: true,
					change: false,
					viewColumns: false,
				},
			},
			{
				label: 'Order Date',
				name: 'orderDate',
				options: {
					display: true,
					change: false,
					viewColumns: false,
					customBodyRender: (value: any) => {
						return UserTimezoneFormat(props.properties, value);
					},
				},
			},
			{
				label: 'Customer Id',
				name: 'customerId',
				options: {
					display: true,
					change: false,
					viewColumns: false,
				},
			},
			{
				label: 'Device Id',
				name: 'cabinetId',
				options: {
					display: true,
					change: false,
					viewColumns: false,
				},
			},
			{
				label: 'Device Type',
				name: 'cabinetType',
				options: {
					display: true,
					change: false,
					viewColumns: false,
					customBodyRender: (value: any) => {
						return dataList.CabinetTypeDropdownList.find(el => value === el.value)?.label ?? '';
					},
				},
			},
			{
				label: 'Order Type',
				name: 'orderType',
				options: {
					display: true,
					change: false,
					viewColumns: false,
				},
			},
			{
				label: 'Order Status',
				name: 'orderStatus',
				options: {
					display: true,
					change: false,
					viewColumns: false,
				},
			},
			{
				label: 'Order Qty',
				name: 'orderQty',
				options: {
					display: true,
					change: false,
					viewColumns: true,
				},
			},
			{
				label: '',
				name: '',
				options: {
					change: false,
					viewColumns: false,
					customBodyRenderLite: (dataIndex: number) => {
						return renderIcons(dataIndex);
					},
				},
			},
		];

		if (props.page === 'orderManagement') {
			groupColumns.splice(groupColumns.length - 2, 0, {
				label: 'Order Notes',
				name: 'notes',
				options: {
					display: false,
					change: false,
					viewColumns: true,
				},
			})
		}

		return groupColumns;
	};

	const ShareModal: React.FC<any> = (actionProps: any) => {
		const linkText = `${window.location.host}/restock-order-detail/${orderId}`;

		return (
			<div
				style={{ ...getModalStyle(), width: isDesktopOrLaptop ? 'auto' : '90%', maxHeight: 800, overflow: 'scroll' }}
				className={modalClasses.paper}
			>
				<div style={{ position: 'absolute', top: 5, right: 5, color: 'rgba(0, 0, 0, 0.26)', cursor: 'pointer' }}>
					<CancelIcon onClick={() => setShareModalOpen(false)} />
				</div>
				<h3>Share Link:</h3>
				<Tooltip title="Click to copy link to clipboard">
					<div
						style={{ cursor: 'pointer' }}
						onClick={() => {
							navigator.clipboard.writeText(linkText);
						}}
					>
						{linkText}
					</div>
				</Tooltip>
			</div>
		);
	};

	const getTableColumns = () => {
		let columns: any[] = columnData;
		if (props.page === 'orderManagement') {
			columns.splice(columns.length - 2, 0, {
				label: 'Order Notes',
				name: 'notes',
				display: false,
				sort: false
			})
		}
		if (groupBy) {
			return getGroupedOrdersColumnData();
		}

		const colData = columns.map((column: any, index: number) => {
			const display = column.display
			return {
				label: column.label,
				name: column.name,
				options: {
					filter: column.name ? true : false,
					display: tableStatePersist ? tableStatePersist.columns[index].display : display,
					customBodyRender: column.customBodyRender ? column.customBodyRender : undefined,
					customBodyRenderLite: column.customBodyRenderLite ? column.customBodyRenderLite : undefined,
					change: column.change,
					viewColumns: column.viewColumns,
					sort: column.sort ?? true,
				},
			};
		});

		return colData;
	};

	const handleGroupBy = (key: string) => {
		props.setPagination({ offset: 0, limit: 10, page: 0 });
		props.setSortOrder('');
		if (key === groupBy) {
			setGroupBy('');
			return;
		}
		setGroupBy(key);
	};

	const getFilter = () => {
		if (groupBy) {
			return getGroupByFilter();
		}
		const filter: any = {
			offset: props.pagination.offset,
			limit: props.globalRowsPerPage,
			where: props.selectedCustomer ? { customerId: props.selectedCustomer.customerId } : {},
			order: props.sortOrder ? [props.sortOrder] : ['created_at DESC'],
		};

		const filterValues = props.selectedFilterValues || {};

		const tableFiltersFromNewComp: any = {
			...(!groupBy && { productId: filterValues['PRODUCT_NAME']?.productItemId || undefined }),
			cabinetId: filterValues['CABINET_ID'] || undefined,
			orderType: filterValues['ORDER_TYPE'] || undefined,
			cabinetType: filterValues['CABINET_TYPE'] || undefined,
			orderStatus: filterValues['ORDER_STATUS'] ? { inq: filterValues['ORDER_STATUS'] } : undefined,
			orderNumber: filterValues['ORDER_NUMBER'] || undefined,
			createdAt: props.selectedDateRange
				? {
						between: [
							moment(props.selectedDateRange.startDate).format('YYYY-MM-DD HH:mm:ss'),
							moment(props.selectedDateRange.endDate).format('YYYY-MM-DD HH:mm:ss'),
						],
				  }
				: undefined,
		};

		Object.keys(tableFiltersFromNewComp).forEach(filterName => {
			if (tableFiltersFromNewComp[filterName]) {
				filter.where[filterName] = tableFiltersFromNewComp[filterName];
			}
		});
		return filter;
	};

	const getGroupByFilter = () => {
		const filterValues = props.selectedFilterValues || {};

		const filter: any = {
			offset: props.pagination.offset,
			limit: props.globalRowsPerPage,
			...(props.sortOrder && { order: props.sortOrder }),
			...(props.selectedCustomer && { customerId: props.selectedCustomer.customerId }),
			...(filterValues['CABINET_ID'] && { cabinetId: filterValues['CABINET_ID'] }),
			...(filterValues['ORDER_TYPE'] && { orderType: filterValues['ORDER_TYPE'] }),
			...(filterValues['CABINET_TYPE'] && { cabinetType: filterValues['CABINET_TYPE'] }),
			...(filterValues['GROUPED_ORDER_STATUS'] && { orderStatus: filterValues['GROUPED_ORDER_STATUS'] }),
			...(filterValues['ORDER_NUMBER'] && { orderNo: filterValues['ORDER_NUMBER'] }),
			...(props.selectedDateRange && { startDate: moment(props.selectedDateRange.startDate).format('YYYY-MM-DD') }),
			...(props.selectedDateRange && { endDate: moment(props.selectedDateRange.endDate).format('YYYY-MM-DD') }),
		};

		return filter;
	};

	const filterOrders = () => {
		props.setPagination({ offset: 0, limit: props.globalRowsPerPage, page: 0 });
	};

	const showLoadingMsg = () => !props.authUser && props.orders.length === 0;

	const getTableData = () => {
		return groupBy ? props.groupedOrders : props.orders;
	};

	const handleFetchOrders = () => {
		if (groupBy) {
			let groupByFilter = getFilter();
			if (groupByFilter.order) groupByFilter.orderBy = groupByFilter.order;
			props.getGroupedOrders({ filter: groupByFilter });
			return;
		}

		props.getOrders({ filter: getFilter() });
	};

	const getTableCount = () => {
		return groupBy ? props.totalGroupedOrders : props.totalOrders;
	};

	const handleGetCsv = (cols: any) => {
		const filter = getFilter();
		filter.limit = 9999;

		const params = constructEnqueueParams(
			'RNI_UIEXPORT_RestockOrdersReport',
			props.authUser?.record.email,
			filter,
			{ columnsRequested: findUserSelectedColumns(cols) },
			GetUserTimezone(props.properties),
		);

		props.sendOneTimeReportRequest(params);
	};

	const handleCloseOrderDetailModal = () => {
		setOrderDetailModalOpen(false);
	};

	React.useEffect(() => {
		if (props.authUser && !firstRender.current) {
			handleFetchOrders();
		} else {
			firstRender.current = false;
		}
	}, [props.pagination, props.sortOrder]);

	const [tableHeight, setTableHeight] = React.useState(0);

	// Calculate table body height as a percentage of the viewport height
	React.useEffect(() => {
		const viewportHeight = window.innerHeight;
		const percentageHeight = 65; // Adjust this value as needed
		const calculatedHeight = (percentageHeight / 100) * viewportHeight;
		setTableHeight(calculatedHeight);
	}, []);

	const submitOrderNotes = () => {
		const payload = {
			orderNumber: selectedOrder.orderNumber,
			requestBody: {
				orderLine: selectedOrder.orderLine,
				notes: orderNotes || null,
			},
			success: () => {
				setOrderNotesLoading(false);
				handleFetchOrders();
				setOrderNotesModalOpen(false);
			},
			error: () => {
				setOrderNotesLoading(false);
				setOrderNotesFailureMsg('Failed to update order notes');
			},
		};

		props.submitOrderNotes(payload);
	}

	const handleTabChange = (event: any, newValue: string) => {
		if (newValue === props.tabView) {
			return;
		}
		props.setTabView(newValue);
		props.setSortOrder('');
		props.setPagination({ offset: 0, limit: props.globalRowsPerPage, page: 0 });
	};

	return (
		<div>
			{showLoadingMsg() ? (
				<div>
					<Loading message="" />
				</div>
			) : (
				<div>
					{props.tabView === 'orderRequests' && <div style={{marginBottom: 25, textAlign: 'right'}}>
							<Button
								type="button"
								className={classes.searchButton}
								variant="contained"
								color="primary"
								onClick={() => {
									setOrderRequestModalOpen(true)
								}}
							>
								Request an Order
							</Button>
						</div>}
					<div>
						{props.page === 'customerOrders' && <AppBar position="static" color="default">
							<Tabs
								variant="scrollable"
								scrollButtons="on"
								aria-label="scrollable tabs"
								value={props.tabView}
								indicatorColor="secondary"
								textColor="primary"
								onChange={handleTabChange}
							>
								<Tab value="restockOrders" label="Restock Orders" />
								<Tab value="orderRequests" label="Order Requests" />
							</Tabs>
						</AppBar>}
						{(props.tabView === 'restockOrders' || props.page === 'orderManagement') && <>
							{(props.orders && props.orders.length > 0) || (props.groupedOrders && props.groupedOrders.length > 0) ? (
								<div>
									<div
										style={{
											position: 'relative',
										}}
									>
										<AppBar position="static" color="default">
											<div style={{ width: '100%', height: '48px', textAlign: 'left' }}>
												<div className={classes.groupByWrapper}>
													<h4 className={classes.groupByHeading}>Group by</h4>
													<FormControl className={classes.groupByLinks}>
														<Button
															variant={groupBy === 'orderNumber' ? 'contained' : 'text'}
															endIcon={
																groupBy === 'orderNumber' ? (
																	<CancelIcon className={classes.groupByButtons}></CancelIcon>
																) : (
																	''
																)
															}
															onClick={() => handleGroupBy('orderNumber')}
														>
															<span className={classes.groupByButtons}>ORDER NUMBER</span>
														</Button>
													</FormControl>
												</div>
											</div>
										</AppBar>
									</div>
									<MUIDataTable
										data={getTableData()}
										columns={getTableColumns()}
										options={{
											rowsPerPage: props.globalRowsPerPage,
											rowsPerPageOptions: dataList.PageLimitOptions,
											onChangeRowsPerPage: (numberOfRows: number) => {
												props.setPagination({ ...props.pagination, limit: numberOfRows });
												props.setGlobalRowsPerPage(numberOfRows);
											},
											onDownload: (buildHead: any, buildBody: any, columns: any, data: any) => {
												handleGetCsv(columns);
												return false;
											},
											filterType: 'dropdown',
											responsive: 'simple',
											filter: false,
											search: false,
											download: true,
											print: true,
											selectableRows: 'none',
											serverSide: true,
											page: props.pagination.page,
											count: getTableCount(),
											fixedHeader: true, // This locks the table headers at the top
											tableBodyHeight: tableHeight + 'px', // Set the height for the table body
											onTableChange: (tableAction: any, tableState: any) => {
												if (tableAction == 'changePage') {
													props.setPagination(tablePagination(tableAction, tableState, props.pagination));
												} else if (tableAction == 'sort') {
													props.setSortOrder(tableState.sortOrder.name + ' ' + tableState.sortOrder.direction);
													return;
												} else if (tableAction == 'viewColumnsChange') {
													setTableStatePersist(tableState);
												}
												if (!props.sortOrder) {
													tableState.sortOrder = {};
												}
											},
										}}
									/>
								</div>
							) : (
								<EmptyContent message="Change the filter criteria above to view order information." />
							)}
						</>}
						<>
							{props.tabView === 'orderRequests' && 
								<OrderRequestsComponent
									selectedCustomer={props.selectedCustomer}
									page={'customerOrderRequests'}
									pagination={props.pagination} 
									setPagination={props.setPagination}
									sortOrder={props.sortOrder}
									setSortOrder={props.setSortOrder}
									modalOpen={orderRequestModalOpen}
									setModalOpen={setOrderRequestModalOpen}
								/>
							}
						</>
					</div>
				</div>
			)}
			<Modal
				open={orderDetailModalOpen}
				onClose={handleCloseOrderDetailModal}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<div>
					<RestockOrderDetailContainer
						close={handleCloseOrderDetailModal}
						orderId={orderId}
						isModal={true}
						handleFetchOrders={handleFetchOrders}
					/>
				</div>
			</Modal>

			<Modal
				open={shareModalOpen}
				onClose={() => setShareModalOpen(false)}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<div>
					<ShareModal />
				</div>
			</Modal>

			<Modal
				open={orderNotesModalOpen}
				onClose={() => setOrderNotesModalOpen(false)}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<div
					style={{ ...getModalStyle(), width: isDesktopOrLaptop ? 'auto' : '90%', maxHeight: 800, overflow: 'scroll' }}
					className={modalClasses.paper}
				>
					<div style={{ position: 'absolute', top: 5, right: 5, color: 'rgba(0, 0, 0, 0.26)', cursor: 'pointer' }}>
						<CancelIcon onClick={() => setOrderNotesModalOpen(false)} />
					</div>
					<h3>Update Order Notes:</h3>
					<Grid container>
						<Grid item xs={12}>
							<FormControl style={{ width: '100%' }} className={classes.formControl}>
								<TextField
									label="Order notes"
									placeholder=""
									variant="outlined"
									multiline
									value={orderNotes}
									rows={2}
									rowsMax={2}
									onChange={(e: any) => {
										setOrderNotes(e.target.value);
										setOrderNotesFailureMsg('');
									}}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<FormControl>
								{orderNotesLoading ? 
									<Loading message="" />
								:
									<Button
										type="button"
										className={classes.searchButton}
										variant="contained"
										color="primary"
										onClick={() => {
											submitOrderNotes();
											setOrderNotesLoading(true)
										}}
									>
										Save
									</Button>
								}
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							{orderNotesFailureMsg && (
								<div className={classes.errorMsg}>{orderNotesFailureMsg}</div>
							)}
						</Grid>
					</Grid>
				</div>
			</Modal>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	orders: state.orders.orderList ? state.orders.orderList.result : [],
	groupedOrders: state.orders.groupedOrderList ? state.orders.groupedOrderList.result : [],
	totalOrders: state.orders.orderList ? state.orders.orderList.total_record : 0,
	totalGroupedOrders: state.orders.groupedOrderList ? state.orders.groupedOrderList.total_record : 0,
	authUser: state.user.authUser,
	selectedCustomer: state.ui.selectedCustomer,
	tenants: state.tenants?.tenantsList?.result || [],
	products: state.product.productList || {
		result: [],
		total: 0,
	},
	autoCompleteProducts: state.product.autoCompleteList || [],
	statesList: state.cabinet.stateList ? state.cabinet.stateList.result : [],
	selectedFilterValues: state.ui.selectedFilterValues,
	selectedDateRange: state.ui.selectedDateRange,
	globalRowsPerPage: state.ui.globalRowsPerPage ? state.ui.globalRowsPerPage : 10,
	properties: Array.isArray(state.user?.authUser?.record?.properties) ? state.user.authUser.record.properties : [],
});

const mapDispatchToProps = (dispatch: any) => ({
	searchTenants: (filter: any) => dispatch(TenantsActions.getTenantList(filter)),
	getOrders: (payload: any) => dispatch(OrderActions.getOrderList(payload)),
	getGroupedOrders: (payload: any) => dispatch(OrderActions.getGroupedOrderList(payload)),
	resetTenants: () => dispatch(TenantsActions.clearTenantsList(true)),
	setSelectedCustomer: (customer: any) => dispatch(UIAction.setSelectedCustomer(customer)),
	getProducts: (filter: any) => dispatch(ProductActions.getProductList(filter)),
	getAutoCompleteProducts: (filter: any) => dispatch(ProductActions.getProductAutoCompleteList(filter)),
	setGlobalRowsPerPage: (rowsPerPage: number) => dispatch(UIAction.setGlobalRowsPerPage(rowsPerPage)),
	sendOneTimeReportRequest: (params: any) => dispatch(UserActions.sendOneTimeReport(params)),
});
export default connect(mapStateToProps, mapDispatchToProps)(OrdersComponent);
